import { useSelector } from 'react-redux';
import { Moment } from 'moment';

import {
  BUY,
  FinancialInstrument,
  ForwardContract,
  HISTORICAL_SCENARIO,
  marketHours,
  selectors,
  SELL,
  Session,
  UnderlyingAsset,
} from 'state';
import * as types from './types';
import { ONGOING_TBILL_CONTRACT } from './auctions/types';
import { API } from 'services';
import { IndexedObject } from 'services/indexedObject';

export const assetIsOpen = (
  asset: types.UnderlyingAsset,
  session: types.Session
): [boolean, boolean] => {
  // return [true, false]; //make the markets always open
  const { scenario_type } = session;
  // const { trading_intervals } = session;

  if (scenario_type === HISTORICAL_SCENARIO) return [true, false];

  if (asset.resourcetype === 'Crypto') return [true, false];

  const today = new Date();
  const time = today.toISOString().split('T')[1].slice(0, 5);
  const isWeekend = today.getUTCDay() === 0 || today.getUTCDay() === 6;

  if (isWeekend) return [false, false];

  if (asset.resourcetype.endsWith('Bond')) return [true, false];

  // TODO: FIX THIS
  // let isOpen = [true, false];
  //
  // if (trading_intervals.length) {
  //   const activeIntervals = trading_intervals.filter(
  //     item => time >= item.start && time < item.end
  //   );
  //
  //   if (!activeIntervals.length) isOpen = [false, true];
  // }
  //
  // if (!isOpen) return [false, false];

  const hours = marketHours[asset.geography] || {
    open: '0',
    close: '24',
  };

  return [time > hours.open && time < hours.close, false];
};

export const useReportingCurrency = (session_id: number) => {
  const reportingCurrencyId = useSelector(selectors.sessionById(session_id))
    .reporting_currency;
  return useSelector(selectors.currencyById(reportingCurrencyId));
};

const convertStringToCurrencyID = (
  from_currency: number | string,
  to_currency: number | string,
  currenciesList: types.Currency[]
) => {
  // This function converts currency given as string in currency IDs. i.e. "USD" => 1
  if (typeof from_currency === 'string') {
    from_currency =
      currenciesList.find(item => item.symbol === from_currency)?.id || -1;
  }

  if (typeof to_currency === 'string') {
    to_currency =
      currenciesList.find(item => item.symbol === to_currency)?.id || -1;
  }
  return [from_currency, to_currency];
};
/**
 * This function takes two currencies and returns the correct currency pair object.(underlying asset object)
 *
 * i.e. "USD/EUR" does not exist in FX world, but "EUR/USD" does.
 */
export const getCurrencyPair = (
  from_currency: number | string,
  to_currency: number | string,
  assetsList: types.UnderlyingAsset[]
) => {
  // the below checks to see if, for example, "USD/EUR" exists.
  let currencyPair = assetsList.find(
    item =>
      item.base_currency === from_currency &&
      item.variable_currency === to_currency &&
      item.resourcetype === 'FX'
  );
  // if it didn't find any prices for "USD/EUR", it means the pair doesn't exist, and it must be "EUR/USD"
  if (currencyPair === undefined) {
    currencyPair = assetsList.find(
      item =>
        item.base_currency === to_currency &&
        item.variable_currency === from_currency &&
        item.resourcetype === 'FX'
    );
  }

  return currencyPair;
};

export const getFWDCurrencyPair = (
  from_currency: number | string,
  to_currency: number | string,
  assetsList: types.UnderlyingAsset[]
) => {
  // the below checks to see if, for example, "USD/EUR" exists.
  let currencyPair = assetsList.find(
    item =>
      item.base_currency === from_currency &&
      item.variable_currency === to_currency &&
      item.resourcetype === 'FxForwards'
  );
  // if it didn't find any prices for "USD/EUR", it means the pair doesn't exist, and it must be "EUR/USD"
  if (currencyPair === undefined) {
    currencyPair = assetsList.find(
      item =>
        item.base_currency === to_currency &&
        item.variable_currency === from_currency &&
        item.resourcetype === 'FxForwards'
    );
  }

  return currencyPair;
};

const exchangeTransactionDirection = (
  from_currency: number,
  to_currency: number,
  currencyPair: types.UnderlyingAsset
) => {
  return to_currency === currencyPair.base_currency ? BUY : SELL;
};

export const useExchangeRate = (
  from_currency: number | string,
  to_currency: number | string
) => {
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const { session } = useSelector(selectors.player);
  const reportingCurrency = useReportingCurrency(session);

  if (from_currency === to_currency) return 1;
  const [fromCurrency, toCurrency] = convertStringToCurrencyID(
    from_currency,
    to_currency,
    currencyList
  );
  // This does not allow cash transactions that don't pass through session reporting currency.
  // This works because of conditional formatting in ExchangeModal.tsx that prints "not available" if exchange rate is 1.
  const repCcy =
    currencyList.find(ccy => ccy.symbol === reportingCurrency.symbol)?.id ?? -1;
  if (repCcy === -1) {
    return 1;
  }

  if (fromCurrency !== repCcy && toCurrency !== repCcy) return 0;
  return exchangeRate(from_currency, to_currency, assetList, currencyList);
};

export const exchangeRate = (
  from_currency: number | string,
  to_currency: number | string,
  assetsList: types.UnderlyingAsset[],
  currencyList: types.Currency[],
  getFWDRate?: boolean | undefined
) => {
  // There's no exchange rate between USD and USD, 1 USD = 1 USD
  if (from_currency === to_currency) return 1;
  const [fromCurrency, toCurrency] = convertStringToCurrencyID(
    from_currency,
    to_currency,
    currencyList
  );

  // This does not allow cash transactions that don't pass through USD.
  // This works because of conditional formatting in ExchangeModal.tsx that prints "not available" if exchange rate is 0.
  // const usd = currencyList.find(ccy => ccy.symbol === 'USD')?.id ?? -1;
  // if (usd === -1) {
  //   return 1;
  // }

  // if (fromCurrency !== usd && toCurrency !== usd) return 0;
  let currencyPair;
  if (getFWDRate) {
    currencyPair = getFWDCurrencyPair(fromCurrency, toCurrency, assetsList);
  } else {
    currencyPair = getCurrencyPair(fromCurrency, toCurrency, assetsList);
  }
  if (currencyPair === undefined) {
    return 1;
  }

  const transactionDirection = exchangeTransactionDirection(
    fromCurrency,
    toCurrency,
    currencyPair
  );

  const bid = currencyPair.bid;
  const ask = currencyPair.ask;

  if (transactionDirection === BUY) return ask;
  // If you want to exchange dollars for euros, you are essentially buying the EUR/USD "asset". (buying EUR, selling USD)
  // This "asset" can be bought at ask price and sold at bid price.
  else return bid;
};
/**
 * To get to_amount, pass from_amount and leave to_amount = 0.
 *
 * Example: cashExchange(from_currency, to_currency, exchange_rate, from_amount, 0)
 *
 * To get from_amount, leave from_amount = 0 and pass to_amount
 *
 * Example: cashExchange(from_currency, to_currency, exchange_rate, 0, to_amount)
 */
export const cashExchange = (
  from_currency: number | string,
  to_currency: number | string,
  from_amount: number,
  to_amount: number,
  assetsList: types.UnderlyingAsset[],
  currencyList: types.Currency[],
  customExchangeRate?: number | undefined,
  exchangeAtFwdRate?: boolean | undefined
) => {
  const [fromCurrency, toCurrency] = convertStringToCurrencyID(
    from_currency,
    to_currency,
    currencyList
  );

  // HANDLE NO CCY
  if (fromCurrency === -1 || toCurrency === -1) {
    return from_amount;
  }

  const currencyPair = getCurrencyPair(fromCurrency, toCurrency, assetsList);
  // HANDLE NO PAIR
  if (currencyPair === undefined) {
    return from_amount;
  }

  const transactionDirection = exchangeTransactionDirection(
    fromCurrency,
    toCurrency,
    currencyPair
  );
  let exchange_rate;
  if (exchangeAtFwdRate) {
    exchange_rate = exchangeRate(
      fromCurrency,
      toCurrency,
      assetsList,
      currencyList,
      true
    );
  } else {
    exchange_rate = exchangeRate(
      fromCurrency,
      toCurrency,
      assetsList,
      currencyList,
      false
    );
  }
  if (customExchangeRate && customExchangeRate !== 0) {
    exchange_rate = customExchangeRate;
  }

  // this will be either to_amount or from_amount, depends on which of them have been provided and which is
  // to be calculated (CASE1 or CASE2)
  let convertedAmount = 0;

  if (to_amount === 0) {
    // CASE 1: The function will calculate how many Euros you will receive by exchanging "from_amount" of Dollars
    // ("100 USD is how much EUR?")
    // Here, from_amount is provided and to_amount is to be calculated.
    if (transactionDirection === SELL) {
      convertedAmount = from_amount * exchange_rate;
    } else {
      convertedAmount = from_amount / exchange_rate;
    }
  }

  if (from_amount === 0) {
    // CASE 2: The function will calculate how many Euros you need to exchange in order to receive "to_amount" of Dollars ("How much EUR will it cost me to get 100 USD?")
    // Here, to_amount is provided and from_amount is to be calculated.
    if (transactionDirection === SELL) {
      convertedAmount = to_amount / exchange_rate;
    } else {
      convertedAmount = to_amount * exchange_rate;
    }
  }

  return convertedAmount;
};

export const reportingValue = (
  // amount * avg_open_price (which for bonds is clean), then converted to reporting currency
  item: types.FinancialInstrument,
  currency: types.Currency,
  resourcetype: string,
  assetsList: types.UnderlyingAsset[],
  currencyList: types.Currency[],
  reportingCurrency: types.Currency
) => {
  if (!item.amount) {
    return 0;
  }
  let reportingValue;
  if (resourcetype === 'FxForwards') {
    if (currency === reportingCurrency) {
      return item.amount;
    } else {
      reportingValue = cashExchange(
        currency.id,
        reportingCurrency.id, // to_currency
        item.amount, // from_amount
        0,
        assetsList,
        currencyList
      );
      return reportingValue;
    }
  }
  if (resourcetype === 'FX') {
    const instrumentValue = item.amount; // expressed in base currency of instrument
    if (currency.id === reportingCurrency.id) {
      reportingValue = instrumentValue;
    } else {
      reportingValue = cashExchange(
        currency.id,
        reportingCurrency.id, // to_currency
        instrumentValue, // from_amount
        0,
        assetsList,
        currencyList
      );
    }
  } else {
    const instrumentValue = item.avg_open_price * item.amount;
    reportingValue = cashExchange(
      currency.id,
      reportingCurrency.id, // to_currency
      instrumentValue, // from_amount
      0,
      assetsList,
      currencyList
    );
  }

  if (resourcetype.endsWith('Bond')) {
    reportingValue /= 100;
  }

  return Math.abs(reportingValue);
};

const workdayCountFreq1 = (start: Moment, end: Moment) => {
  const first = start.clone().endOf('week'); // end of first week
  const last = end.clone().startOf('week'); // start of last week
  const days = (last.diff(first, 'days') * 5) / 7; // this will always multiply of 7
  let wfirst = first.day() - start.day(); // check first week
  if (start.day() == 0) --wfirst; // -1 if start with sunday
  let wlast = end.day() - last.day(); // check last week
  if (end.day() == 6) --wlast; // -1 if end with saturday
  return wfirst + Math.floor(days) + wlast; // get the total
};

export const workdayCount = (start: Moment, end: Moment, frequency = 1) => {
  if (frequency === 1) {
    return workdayCountFreq1(start, end);
  }
  const first = start.clone();
  const last = end.clone().add(4, 'days');

  const offsets = [1, 0, 0, 0, 0, 0, 2];

  let days = 0;
  while (last.diff(first, 'days') >= 0) {
    first.add(frequency, 'days');

    const weekday = first.weekday();
    if ([0, 6].includes(weekday)) {
      first.add(offsets[weekday]);
    }

    days += 1;
  }
  return days;
};

export const formatStringDate = (stringDate: string) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const dateObj = new Date(stringDate);
  dateObj.setDate(dateObj.getDate() - 1);
  const year = dateObj.getFullYear();
  const day = dayFormatter(String(dateObj.getDate()));
  const formattedMonth = new Intl.DateTimeFormat('en', {
    month: 'short',
  }).format(dateObj); // Get the abbreviated month name
  const dayOfWeekName = daysOfWeek[dateObj.getDay()];
  return `${year} ${formattedMonth} ${day}, ${dayOfWeekName}`;
};

export const dayFormatter = (day: string) => {
  if (['11', '12', '13'].includes(day)) {
    day = day + 'th';
  } else if (day[day.length - 1] === '1') {
    day = day + 'st';
  } else if (day[day.length - 1] === '2') {
    day = day + 'nd';
  } else if (day[day.length - 1] === '3') {
    day = day + 'rd';
  } else {
    day = day + 'th';
  }
  return day;
};

export const formatStringDateTraineeInfo = (stringDate: string) => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  const dateObj = new Date(stringDate);
  dateObj.setDate(dateObj.getDate() - 1);
  const day = dayFormatter(String(dateObj.getDate()));

  const dayOfWeekName = daysOfWeek[dateObj.getDay()];
  return `${dayOfWeekName}, ${day}`;
};

export const formattedHistoricalStep = (seconds: number) => {
  const minutes = Math.floor((seconds / 60) % 60);
  const formattedMins = String(minutes).padStart(2, '0'); // turns 0 in 00, 1 in 01 and 10 in 10
  const secondsLeft = String(seconds - minutes * 60).padStart(2, '0');
  return `${formattedMins}m:${secondsLeft}s`;
};

export const forwardInstrumetnReportingValue = (
  instrument: FinancialInstrument,
  underlyingAsset: UnderlyingAsset,
  session: Session,
  assetsList: types.UnderlyingAsset[],
  currencyList: types.Currency[],
  contractsIndexed: IndexedObject<ForwardContract>
) => {
  // console.log('contractsIndexed utils', contractsIndexed);
  const totalInstrumentValueByContractSum = instrument.forward_contracts.reduce(
    function (total, currentValue) {
      const contract = contractsIndexed[currentValue];
      // console.log('contract: ', contract);
      // let closed = false;
      // if (contract.closed_by !== null) {
      //   closed = true;
      // }
      if (
        contract.closed_by ||
        contract.closing_contract_for ||
        contract.delivery_timestamp
      ) {
        return total;
      }
      return (
        total +
        cashExchange(
          underlyingAsset.base_currency,
          session.reporting_currency,
          contract.amount,
          0,
          assetsList,
          currencyList,
          0,
          true
        )
      );
    },
    0
  );
  return totalInstrumentValueByContractSum;
};

export const tBillInstrumentReportingValue = (
  instrument: FinancialInstrument,
  underlyingAsset: UnderlyingAsset,
  session: Session,
  assetsList: types.UnderlyingAsset[],
  currencyList: types.Currency[]
) => {
  const totalInstrumentValueByContractSum = instrument.tbill_contracts.reduce(
    function (total, currentContract) {
      if (currentContract.status !== ONGOING_TBILL_CONTRACT) {
        return total;
      }
      return (
        total +
        cashExchange(
          underlyingAsset.base_currency,
          session.reporting_currency,
          (underlyingAsset.bid / 100) * currentContract.amount,
          0,
          assetsList,
          currencyList,
          0,
          false
        )
      );
    },
    0
  );
  return totalInstrumentValueByContractSum;
};
