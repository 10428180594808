import { appManager, AppState } from '../manager';
import current from 'immer';
import {
  addElementToIndexedArray,
  arrayToIndexedObject,
  makeAPIProp,
} from 'services/indexedObject';

import * as types from '../types';
import { TRANSACTION_TYPES } from '../constants';
import { authManager, utils } from '../../index';
import Cookies from 'js-cookie';
import {
  AssetClassOptions,
  LoanRateOfferOptions,
  MarginOptions,
  // RateOfferOptions,
} from '../types';
import { notification } from 'antd';

// NOTE TO DEVELOPER:
//  PAGINATED SESSIONS ARE ONLY USED FOR PERFORMANCE REASONS IN THE ADMIN TAB
//  ALL player and / or relevant data is to be stored in the INDEXED SESSIONS
//  Duplicating data in redux is not a good idea
// TODO: When fetching paginated sessions, get the data and store it in the indexed sessions, then store the ids in the
//  paginated state. Therefore, removing and duplicated data.
//  Consider caching pages and creating cache clearing for specific actions like: DELETE, UPDATE, CREATE OR
//  create a system where paginated api provides only ids and then the frontend requests the ids which are not stored
//  in state.

export const createSession = appManager.createApi<
  types.CreateSessionPayload,
  types.Session,
  AppState
>('CREATE_SESSION', {
  path: '/sessions',
  method: 'POST',
  successReducer() {
    /* there is a socket for this */
  },
});

export const deleteSession = appManager.createApi<
  { id: number },
  { id: number },
  AppState
>('DELETE_SESSION', {
  path: '/sessions/:id/delete',
  method: 'POST',
  successReducer(state, result) {
    // Indexed sessions
    delete state.sessions.items[result.id];

    // Admin list sessions
    const index = state.paginated_sessions.items.findIndex(
      s => s.id === result.id
    );
    if (index !== -1) {
      state.paginated_sessions.items.splice(index, 1);
      if (state.paginated_sessions.count) {
        state.paginated_sessions.count -= 1;
      }
    }
  },
});

export const fetchSessionAssets = appManager.createApi<
  { id: number },
  // unknown,
  types.UnderlyingAsset[],
  AppState
>('FETCH_SESSION_ASSETS', {
  path: '/sessions/:id/fetch_session_assets',
  method: 'GET',
  successReducer(state, result) {
    state.underlying_assets.items = arrayToIndexedObject(result);
    state.underlying_assets.fetched = true;
  },
});

appManager.createSocketListener<types.Session, AppState>(
  'session_created',
  (state, result) => {
    addElementToIndexedArray(state.sessions.items, result);
  }
);

appManager.createSocketListener<types.Session, AppState>(
  'session_updated',
  (state, result) => {
    // Indexed sessions
    state.sessions.items[result.id] = result;

    // Admin list sessions
    const index = state.paginated_sessions.items.findIndex(
      s => s.id === result.id
    );
    if (index !== -1) {
      const session = state.paginated_sessions.items[index];
      state.paginated_sessions.items[index] = { ...session, ...result };
    }
  }
);

export const fetchSessions = appManager.createApi<
  unknown,
  types.Session[],
  AppState
>('FETCH_SESSIONS', {
  path: '/sessions',
  method: 'GET',
  startReducer(state) {
    state.sessions.waiting = true;
  },
  successReducer(state, result) {
    state.sessions.items = arrayToIndexedObject(result);
    state.sessions.waiting = false;
  },
  failReducer(state) {
    state.sessions.waiting = false;
  },
});

export const fetchHistoricalStep = appManager.createApi<
  unknown,
  {
    sleep_time: number;
  },
  AppState
>('FETCH_HISTORICAL_STEP', {
  path: '/sessions/get_historical_period_length',
  method: 'GET',

  successReducer(state, result) {
    // console.log(result);
    state.historical_period_length = result.sleep_time;
  },
});

export const updateHistoricalStep = appManager.createApi<
  {
    sleep_time: number | undefined;
  },
  {
    sleep_time: number;
  },
  AppState
>('UPDATE_HISTORICAL_STEP', {
  path: '/sessions/update_historical_period_length',
  method: 'POST',

  successReducer(state, result) {
    state.historical_period_length = result.sleep_time;
  },
});

appManager.createSocketListener<
  {
    sleep_time: number;
    remaining_time: string;
  },
  AppState
>('historical_step_updated', (state, result) => {
  const session = state.sessions.items[state.active_session];
  state.historical_period_length = result.sleep_time;
  session.remaining_session_time = result.remaining_time;
});

export const fetchPaginatedSessions = appManager.createApi<
  { page_number?: number; page_size?: number; params?: string },
  {
    count: number;
    page_number: number;
    page_size: number;
    results: types.Session[];
    // csrftoken: string;
  },
  AppState
>('FETCH_PAGINATED_SESSIONS', {
  path: '/sessions/paginated_list',
  method: 'GET',
  startReducer(state) {
    state.paginated_sessions.waiting = true;
  },
  successReducer(state, result) {
    state.paginated_sessions.items = result.results;
    state.paginated_sessions.count = result.count;
    state.paginated_sessions.page_size = result.page_size;
    state.paginated_sessions.page_number = result.page_number;
    state.paginated_sessions.waiting = false;
    // Cookies.set('csrftoken', result.csrftoken);
    // console.log('csrftoken: ', result.csrftoken);
  },
  failReducer(state) {
    state.paginated_sessions.waiting = false;
  },
});

export const fetchPlayers = appManager.createApi<
  { session_id: number },
  types.Player[],
  AppState
>('FETCH_PLAYERS', {
  path: '/sessions/:session_id/fetch_players',
  method: 'GET',
  startReducer(state, payload) {
    state.sessions.items[payload.session_id].players_fetched = true;
  },
  successReducer(state, result) {
    state.players.items = {
      ...state.players.items,
      ...arrayToIndexedObject(result),
    };
  },
});

export const fetchPassedNews = appManager.createApi<
  { session_id: number },
  types.News[],
  AppState
>('FETCH_PASSED_NEWS', {
  path: '/sessions/:session_id/fetch_passed_news',
  method: 'GET',
  startReducer(state, payload) {
    state.sessions.items[payload.session_id].news_fetched = true;
    state.sessions.items[payload.session_id].fetching_news = true;
  },
  successReducer(state, result, payload) {
    state.news.items = {
      ...state.news.items,
      ...arrayToIndexedObject(result),
    };
    state.sessions.items[payload.session_id].fetching_news = false;
  },
});

export const fetchAllocations = appManager.createApi<
  { session_id: number },
  { player_id: number; allocations: types.TacticalAssetAllocation[] }[],
  AppState
>('FETCH_ALLOCATIONS', {
  path: '/sessions/:session_id/fetch_tactical_asset_allocations',
  method: 'GET',
  startReducer(state, payload) {
    const session = state.sessions.items[payload.session_id];
    session.tactical_allocations_fetching = true;
  },
  successReducer(state, result, payload) {
    result.forEach(
      ({ player_id, allocations }) =>
        (state.players.items[
          player_id
        ].tactical_asset_allocations = allocations)
    );
    const session = state.sessions.items[payload.session_id];
    session.tactical_allocations_fetching = false;
    session.tactical_allocations_fetched = true;
  },
});

export const fetchAssetGroupAllocations = appManager.createApi<
  { session_id: number },
  types.AssetGroupAllocation[],
  AppState
>('FETCH_ASSET_GROUP_ALLOCATIONS', {
  path: '/sessions/:session_id/fetch_asset_group_allocations',
  method: 'GET',
  startReducer(state, payload) {
    const session = state.sessions.items[payload.session_id];
    session.fetching_asset_group_allocations = true;
  },
  successReducer(state, result, payload) {
    const session = state.sessions.items[payload.session_id];
    session.asset_group_allocations = result;
    session.fetching_asset_group_allocations = false;
    session.fetched_asset_group_allocations = true;
  },
  failReducer(state, error, payload) {
    const session = state.sessions.items[payload.session_id];
    session.fetching_asset_group_allocations = false;
  },
});

export const fetchMetrics = appManager.createApi<
  { session_id: number },
  {
    id: number;
    metrics: types.Metrics[];
    player_history: types.PlayerHistory[];
  }[],
  AppState
>('FETCH_METRICS', {
  path: '/sessions/:session_id/fetch_metrics',
  method: 'GET',
  startReducer(state, payload) {
    state.sessions.items[payload.session_id].metrics_fetching = true;
    state.sessions.items[payload.session_id].metrics_fetched = true;
  },
  successReducer(state, result, payload) {
    result.forEach(({ id, metrics, player_history }) => {
      state.players.items[id].metrics = metrics;
      state.players.items[id].player_history = player_history;
    });
    state.sessions.items[payload.session_id].metrics_fetching = false;
  },
});

export const playPauseSession = appManager.createApi<
  {
    id: number;
    current_status: 0 | 1 | 2 | 3 | 4 | 5;
    reload?: boolean;
  },
  { id: number; status: types.Session['status'] },
  AppState
>('PLAY_PAUSE_SESSION', {
  path: '/sessions/:id/play_pause/',
  method: 'POST',
  successReducer(state, result, payload) {
    // Admin list sessions (Admin sessions)
    const index = state.paginated_sessions?.items.findIndex(
      s => s.id === result.id
    );
    if (index !== -1) {
      state.paginated_sessions.items[index].status = result.status;
      return;
    }

    // Indexed session (Trainer dashboard && Trainer session)
    state.sessions.items[result.id].status = result.status;

    payload.reload && window.location.reload();
  },
  failReducer(state, error, payload) {
    // Admin list sessions (Admin sessions)
    const index = state.paginated_sessions?.items.findIndex(
      s => s.id === payload.id
    );
    if (index !== -1) {
      state.paginated_sessions.items[index].status = payload.current_status;
      return;
    }

    // Indexed session (Trainer dashboard && Trainer session)
    state.sessions.items[payload.id].status = payload.current_status;

    payload.reload && window.location.reload();
  },
});

export const freezeSession = appManager.createApi<
  { id: number },
  { id: number; status: types.Session['status'] },
  AppState
>('FREEZE_SESSION', {
  path: '/sessions/:id/freeze_session/',
  method: 'POST',
  successReducer(state, result, payload) {
    // Admin list sessions (Admin sessions)
    const index = state.paginated_sessions?.items.findIndex(
      s => s.id === result.id
    );
    if (index !== -1) {
      state.paginated_sessions.items[index].status = result.status;
      return;
    }
    // Indexed session (Trainer dashboard && Trainer session)
    state.sessions.items[result.id].status = result.status;

    // payload.reload && window.location.reload();
  },
});

export const switchHTMPermission = appManager.createApi<
  { id: number },
  { id: number; allow_htm: boolean },
  AppState
>('SWITCH_HTM', {
  path: '/sessions/:id/switch_htm_permission',
  method: 'POST',
  successReducer(state, result, payload) {
    state.sessions.items[result.id].allow_htm = result.allow_htm;
  },
});

export const finishSession = appManager.createApi<
  { id: number; reload?: boolean },
  { id: number; status: types.Session['status'] },
  AppState
>('FINISH_SESSION', {
  path: '/sessions/:id/finish',
  method: 'POST',
  successReducer(state, result, payload) {
    // Admin list sessions
    const index = state.paginated_sessions?.items.findIndex(
      s => s.id === result.id
    );
    if (index !== -1) {
      state.paginated_sessions.items[index].status = result.status;
      return;
    }

    // Indexed session
    state.sessions.items[result.id].status = result.status;

    payload.reload && window.location.reload();
  },
});

export const fetchSession = appManager.createApi<
  { id: number },
  types.Session,
  AppState
>('FETCH_SESSION', {
  path: '/sessions/:id/fetch_session',
  method: 'GET',
  successReducer(state, result) {
    state.sessions.items[result.id] = result;
  },
});

export const retrieveSession = appManager.createApi<
  { id: number },
  any,
  AppState
>('RETRIEVE_SESSION', {
  path: '/sessions/:id',
  method: 'GET',
  successReducer(state, result) {
    const active_session_id = result.active_session;
    state.active_session = active_session_id;
    state.sessions.items[active_session_id] = result.session;
    state.persons = makeAPIProp(result.persons);
    if (result.index_funds) state.index_funds = makeAPIProp(result.index_funds);
    if (result.player) {
      // state.player = { item: result.player, waiting: false };
      state.player = {
        item: {
          ...state.player?.item,
          ...result.player,
          financial_instruments: arrayToIndexedObject(
            result.player.financial_instruments
          ),
        },
        waiting: false,
      };
      // state.player.item.financial_instruments = arrayToIndexedObject(
      //   result.player.financial_instruments
      // );
    }
    if (result.players) {
      state.players = makeAPIProp(result.players);
    }
    state.underlying_assets = makeAPIProp(
      result.underlying_assets.map((el: any) => {
        return {
          ...el,
          market_history: {},
        };
      })
    );
    state.currencies = makeAPIProp(result.currencies);
    const reportingCurrency =
      state.currencies.items[result.session.reporting_currency];
    if (!reportingCurrency) {
      return console.error('Reporting currency not found!');
    }
    state.currencies.reportingCurrency = reportingCurrency.id;
  },
});

export const retrievePlayer = appManager.createApi<
  { player_id: number; session_id?: number },
  { player: any; orders: types.Order[] },
  AppState
>('RETRIEVE_PLAYER', {
  path: '/sessions/:session_id/retrieve_player',
  method: 'GET',
  startReducer(state, payload) {
    if (!state.players.items[payload.player_id]) return;
    state.players.items[payload.player_id].fetched_data = true;
  },
  successReducer(state, result) {
    const { player, orders } = result;
    Object.assign(state.players.items[player.id], {
      ...player,
      financial_instruments: arrayToIndexedObject(player.financial_instruments),
      fetched_success: true,
      fetched_data: true,
    });

    const newOrders = arrayToIndexedObject(orders);
    state.orders.items = { ...state.orders.items, ...newOrders };
  },
});

export const updateSessionBenchmarkComposition = appManager.createApi<
  {
    portfolio_composition?: types.BenchmarkPortfolioComposition[];
    composition?: types.BenchmarkComposition[];
    constant?: number;
  },
  {
    portfolio_composition: types.BenchmarkPortfolioComposition[];
    composition: types.BenchmarkComposition[];
    constant: number;
  },
  AppState
>('UPDATE_BENCHMARK_COMPOSITION', {
  path: '/sessions/:session_id/update_benchmark_composition',
  method: 'POST',
  successReducer(state, result) {
    const session = state.sessions.items[state.active_session];
    session.benchmark_compositions = result.composition;
    session.benchmark_portfolio_compositions = result.portfolio_composition;
    session.benchmark_constant = result.constant;
  },
});

export const clearBenchmarkPreview = appManager.createLocalEvent(
  'clear_benchmark_preview',
  state => {
    const session = state.sessions.items[state.active_session];
    session.benchmark_preview = undefined;
  }
);

export const clearInstrumentPreview = appManager.createLocalEvent(
  'clear_instrument_preview',
  state => {
    const session = state.sessions.items[state.active_session];
    session.instrument_preview = undefined;
  }
);

export const requestInstrumentPricePreview = appManager.createApi<
  {
    asset_id: number;
  },
  {
    prices: {
      asset_name: string;
      bid: number[];
      ask: number[];
    };
  },
  AppState
>('PREVIEW_INSTRUMENT_PRICE', {
  path: '/sessions/:session_id/preview_instrument_price',
  method: 'POST',
  successReducer(state, result) {
    const session = state.sessions.items[state.active_session];
    session.instrument_preview = result.prices;
  },
  failReducer(state, result) {
    const session = state.sessions.items[state.active_session];
    session.instrument_preview = { bid: [0], ask: [0], asset_name: 'error' };
  },
});

export const requestBenchmarkPreview = appManager.createApi<
  {
    portfolio_composition?: types.BenchmarkPortfolioComposition[];
    composition?: types.BenchmarkComposition[];
  },
  {
    benchmark_returns: number[];
  },
  AppState
>('PREVIEW_BENCHMARK', {
  path: '/sessions/:session_id/preview_index_benchmark',
  method: 'POST',
  successReducer(state, result) {
    const session = state.sessions.items[state.active_session];
    session.benchmark_preview = result.benchmark_returns;
  },
  failReducer(state, result) {
    const session = state.sessions.items[state.active_session];
    session.benchmark_preview = [0];
  },
});

export const updateAllowIndividualBenchmark = appManager.createApi<
  { allow: boolean },
  { allow: boolean },
  AppState
>('UPDATE_ALLOW_INDIVIDUAL_BENCHMARK', {
  path: '/sessions/:session_id/update_allow_individual_benchmark',
  method: 'POST',
  successReducer(state, result) {
    const session = state.sessions.items[state.active_session];
    session.allow_individual_benchmark = result.allow;
  },
});

appManager.createSocketListener<{ allow: boolean }, AppState>(
  'update_allow_individual_benchmark',
  (state, result) => {
    state.sessions.items[state.active_session].allow_individual_benchmark =
      result.allow;
  }
);
appManager.createSocketListener<{ allow: boolean }, AppState>(
  'update_allow_htm',
  (state, result) => {
    if (result.allow === true) {
      notification.info({
        message: 'HTM re-balancing now allowed.',
      });
    }
    state.sessions.items[state.active_session].allow_htm = result.allow;
  }
);

appManager.createSocketListener<{ delay_minutes_left: number }, AppState>(
  'update_delay',
  (state, result) => {
    // console.log('decreased delay');
    state.sessions.items[state.active_session].delay_minutes_left =
      result.delay_minutes_left;
  }
);

appManager.createSocketListener<types.SessionAssetGroupAllocation[], AppState>(
  'updated_asset_group_allocations',
  (state, result) => {
    const session = state.sessions.items[state.active_session];
    session.asset_group_allocations = result;
  }
);

appManager.createSocketListener<types.AssetClassOptions[], AppState>(
  'updated_asset_class_options',
  (state, result) => {
    const session = state.sessions.items[state.active_session];
    session.asset_class_options = result;
  }
);

appManager.createSocketListener<types.PassTime, AppState>(
  'passed_time',
  (state, result) => {
    try {
      state.sessions.items[state.active_session].elapsed_days =
        result.elapsed_days;
      state.sessions.items[state.active_session].remaining_session_time =
        result.remaining_session_time;
      state.sessions.items[state.active_session].remaining_simulated_days =
        result.remaining_simulated_days;
      state.sessions.items[state.active_session].scenario_date =
        result.scenario_date;
    } catch (error) {
      console.log(error);
    }
  }
);

appManager.createSocketListener<
  {
    fins: {
      id: number;
      htm_afs_days_to_coupon: number;
      htm_afs_years_to_maturity: number;
      htm_afs_dv01: number;
      htm_afs_duration: number;
    }[];
  },
  // unknown,
  // UpdateFins[],
  AppState
>('update_htm_instruments', (state, result) => {
  const instruments = state.player.item.financial_instruments;
  result.fins.forEach(
    ({
      id,
      htm_afs_days_to_coupon,
      htm_afs_years_to_maturity,
      htm_afs_dv01,
      htm_afs_duration,
    }) => {
      instruments[id].htm_afs_days_to_coupon = htm_afs_days_to_coupon;
      instruments[id].htm_afs_years_to_maturity = htm_afs_years_to_maturity;
      instruments[id].htm_afs_dv01 = htm_afs_dv01;
      instruments[id].htm_afs_duration = htm_afs_duration;
    }
  );
});

export const updateTradingIntervals = appManager.createApi<
  { intervals: types.TradingInterval[] },
  types.TradingInterval[],
  AppState
>('UPDATE_TRADING_INTERVALS', {
  path: '/sessions/:session_id/update_trading_windows',
  method: 'POST',
  successReducer(state, result) {
    state.sessions.items[state.active_session].trading_intervals = result;
  },
});

export const updateAssetClassOptions = appManager.createApi<
  { asset_class_options: { [key: number]: AssetClassOptions } },
  // types.AssetClassOptions,
  types.AssetClassOptions[],
  AppState
>('UPDATE_ASSET_CLASS_OPTIONS', {
  path: '/sessions/:session_id/update_asset_class_options',
  method: 'POST',
  successReducer(state, result) {
    // response received on socket at updated_asset_class_options
  },
});

export const updateLeverageSettings = appManager.createApi<
  { margins: { [key: string]: MarginOptions } },
  unknown,
  AppState
>('UPDATE_LEVERAGE_SETTINGS', {
  path: '/sessions/:session_id/update_leverage_settings',
  method: 'POST',
  successReducer(state, result) {
    //
  },
});

appManager.createSocketListener<
  { which_leverage: string; new_percentage: number },
  AppState
>('update_leverage_options', (state, result) => {
  const session = state.sessions.items[state.active_session];
  if (!session) {
    return;
  }
  if (result.which_leverage === 'FX Forwards') {
    session.fx_leverage = result.new_percentage;
  } else {
    session.bond_leverage = result.new_percentage;
  }
});

export const fetchMacroBackground = appManager.createApi<
  { session_id: number },
  { session_id: number; macro_background: string },
  AppState
>('FETCH_MACRO_BACKGROUND', {
  path: '/sessions/:session_id/fetch_macro_background',
  method: 'GET',
  successReducer(state, result) {
    const session = state.sessions.items[result.session_id];

    session.macro_background = result.macro_background;
    session.fetched_macro_background = true;
  },
});

export const updateMacroBackground = appManager.createApi<
  { session_id: number; macro_background: string },
  { session_id: number; macro_background: string },
  AppState
>('UPDATE_MACRO_BACKGROUND', {
  path: '/sessions/:session_id/update_macro_background',
  method: 'POST',
  successReducer() {
    // blank
  },
});

export const updateSessionUnderlyingAssets = appManager.createApi<
  { session_id: number; underlying_assets: number[] },
  unknown,
  AppState
>('UPDATE_UNDERLYING_ASSETS', {
  path: '/sessions/:session_id/update_underlying_assets',
  method: 'POST',
  successReducer(state, result, payload) {
    state.sessions.items[payload.session_id].underlying_assets =
      payload.underlying_assets;
  },
});

export const updateSessionDisplayMetrics = appManager.createApi<
  { session_id: number; display_metrics: number[] },
  unknown,
  AppState
>('UPDATE_DISPLAY_METRICS', {
  path: '/sessions/:session_id/update_display_metrics',
  method: 'POST',
  successReducer(state, result, payload) {
    state.sessions.items[payload.session_id].display_metrics =
      payload.display_metrics;
  },
});

export const closePositions = appManager.createApi<
  { session_id: number },
  unknown,
  AppState
>('CLOSE_POSITIONS', {
  path: '/sessions/:session_id/close_positions',
  method: 'POST',
  successReducer() {
    //
  },
});

export const listOpenOrders = appManager.createApi<
  { session_id: number },
  {
    orders: types.AdminOpenOrder[];
  },
  AppState
>('LIST_OPEN_ORDERS', {
  path: '/sessions/:session_id/open_orders',
  method: 'GET',
  successReducer(state, result, payload) {
    const csvMaker = (orders: types.AdminOpenOrder[]) => {
      const headers: (keyof types.AdminOpenOrder)[] = [
        'session',
        'user',
        'underlying_asset_ric',
        'resourcetype',
        'transaction',
        'currency',
        'limit_price',
        'quantity',
        'timestamp',
      ];

      const csvRows = [];
      csvRows.push(headers.join(','));

      orders
        .map(order => {
          return headers
            .map(field => {
              switch (field) {
                case 'transaction':
                  return TRANSACTION_TYPES[order.transaction];
                default:
                  // MarkerOrder, MarketOnClose, MarketOnOpen don't have limit_price field
                  return order[field] ?? '';
              }
            })
            .join(',');
        })
        .forEach(row => {
          csvRows.push(row);
        });

      return csvRows.join('\n');
    };

    const downloadOrders = (data: string, id: number) => {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', `open_orders_${id}.csv`);
      a.click();
    };

    if (result.orders?.length) {
      const csvData = csvMaker(result.orders);
      downloadOrders(csvData, payload.session_id);
    }
  },
});

export const toggleSound = appManager.createApi<
  { allow_sound: boolean },
  { allow_sound: boolean; session_id: number },
  AppState
>('PATCH_SESSION_SOUND', {
  method: 'POST',
  path: '/sessions/:session_id/toggle_sound',
  successReducer(state, result) {
    const session = state.sessions.items[result.session_id];
    if (!session) {
      return;
    }
    session.allow_sound = result.allow_sound;
  },
});

export const toggleInvestmentRationale = appManager.createApi<
  { require_investment_rationale: boolean },
  { require_investment_rationale: boolean; session_id: number },
  AppState
>('PATCH_REQUIRE_INVESTMENT_RATIONALE', {
  // PATCH wasn't working with csrf check for some reason
  method: 'POST',
  path: '/sessions/:session_id/toggle_require_investment_rationale',
  successReducer(state, result) {
    const session = state.sessions.items[result.session_id];
    if (!session) {
      return;
    }
    session.require_investment_rationale = result.require_investment_rationale;
  },
});

appManager.createSocketListener<
  { allow_sound: boolean; session_id: number },
  AppState
>('toggle_sound', (state, result) => {
  const session = state.sessions.items[result.session_id];
  if (!session) {
    return;
  }
  session.allow_sound = result.allow_sound;
});

appManager.createSocketListener<
  { require_investment_rationale: boolean; session_id: number },
  AppState
>('toggle_require_investment_rationale', (state, result) => {
  const session = state.sessions.items[result.session_id];
  if (!session) {
    return;
  }
  session.require_investment_rationale = result.require_investment_rationale;
});

export const createUpdateGroupAllocations = appManager.createApi<
  { allocations: types.AssetGroupAllocationPayload[]; session_id?: number },
  types.AssetGroupAllocation[],
  AppState
>('CREATE_UPDATE_GROUP_ALLOCATIONS', {
  method: 'POST',
  path: '/sessions/:session_id/create_update_group_allocations',
  successReducer(state, result) {
    const session = state.sessions.items[state.active_session];
    session.asset_group_allocations = result;
  },
});
