import { ApiStateSingle, StateManager } from 'services';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import * as types from './types';

import { API_URL, BASE_URL, CSRF_COOKIE_NAME } from 'settings';
import { SOUND_COOKIE_KEY } from '../app/constants';
import { DecodedToken } from 'services/Api/checkIfTokenExpired';
import { useDispatch } from 'react-redux';
import getCsrfToken from 'services/Api/getCsrfToken';

type State = ApiStateSingle<types.Auth>;

const apiUrl = `${API_URL}/auth`;

const token = Cookies.get('token');
let decodedToken: any = {};
try {
  if (token) decodedToken = jwtDecode(token);
} catch (e) {}

export const authManager = new StateManager({
  apiUrl,
  moduleName: 'auth',
  initialState: {
    item: {
      token,
      userId: decodedToken['user_id'],
      username: decodedToken['username'],
      userType: decodedToken['user_type'],
      email: decodedToken['email'],
      selected_person: Cookies.get('person'),
    },
    waiting: false,
  },
});

const loginReducer = (
  state: State,
  result: { access: string; refresh: string }
) => {
  // console.log('LOGIN REDUCER ====================');
  // console.log('result: ', result);
  const { access, refresh } = result;
  // console.log('token: ', access);
  // console.log('refresh: ', refresh);
  let decodedToken: any;
  try {
    decodedToken = jwtDecode(access) as any;
    state.item = {
      token: result.access,
      refresh: result.refresh,
      userId: decodedToken['user_id'],
      username: decodedToken['username'],
      userType: decodedToken['user_type'],
      email: decodedToken['email'],
      selected_person: state.item.selected_person,
    };
    // console.log('decoded token: ', decodedToken);
    // console.log('userId: ', decodedToken['user_id']);
    // console.log('username: ', decodedToken['username']);
    // console.log('user_type: ', decodedToken['user_type']);
    // console.log('email: ', decodedToken['email']);
    Cookies.set('token', result.access, { sameSite: 'Strict' });
    Cookies.set('token_exp', decodedToken['exp'], { sameSite: 'Strict' });
    Cookies.set('refresh', result.refresh, { sameSite: 'Strict' });
    // console.log('BEFORE CSRF REQUEST ------------');
    getCsrfToken();
  } catch (e) {
    console.log('login error: ', e);
  }
};

const refreshReducer = (state: State, result: { access: string }) => {
  // console.log('REFRESH REDUCER ====================');
  // console.log('result: ', result);
  const { access } = result;
  // console.log('access: ', access);
  let decodedToken: any;
  try {
    decodedToken = jwtDecode(access) as DecodedToken;
    // console.log('decoded token: ', decodedToken);
    // console.log('userId: ', decodedToken['user_id']);
    // console.log('username: ', decodedToken['username']);
    // console.log('user_type: ', decodedToken['user_type']);
    // console.log('email: ', decodedToken['email']);
    state.item = {
      token: result.access,
      // refresh: result.refresh,
      userId: decodedToken['user_id'],
      username: decodedToken['username'],
      userType: decodedToken['user_type'],
      email: decodedToken['email'],
      selected_person: state.item.selected_person,
    };
    Cookies.set('token', result.access);
    Cookies.set('token_exp', decodedToken['exp']);
    // Cookies.set('refresh', result.refresh);
  } catch (e) {
    console.log('login error: ', e);
  }
};

export const login = authManager.createApi<
  { username: string; password: string },
  { access: string; refresh: string },
  State
>('LOGIN', {
  path: '/get_token',
  method: 'POST',
  successReducer: loginReducer,
});

export const refreshToken = authManager.createApi<
  { refresh: string | undefined },
  { access: string },
  State
>('REFRESH_TOKEN', {
  path: '/refresh_token',
  method: 'POST',
  successReducer: refreshReducer,
  failReducer: state => {
    state.item.token = undefined;
    state.item.refresh = undefined;
    state.error = undefined;
    Cookies.remove('token');
    Cookies.remove('token_exp');
    Cookies.remove('refresh');
  },
});

export const logout = authManager.createLocalEvent('LOGOUT', state => {
  state.item.token = undefined;
  Cookies.remove('token');
  Cookies.remove('token_exp');
  Cookies.remove('refresh');
  if (BASE_URL.includes('traderion')) {
    // prod & stage
    Cookies.remove(CSRF_COOKIE_NAME, { domain: '.traderion.com' });
  } else {
    // local
    Cookies.remove(CSRF_COOKIE_NAME);
  }

  state.selected_person = undefined;
  Cookies.remove('person');
});

export const selectPerson = authManager.createLocalEvent(
  'SELECT_PERSON',
  (state: State, payload: number) => {
    state.item.selected_person = payload;
    Cookies.set('person', `${payload}`);
  }
);
