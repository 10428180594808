import { StateManager, ApiStateSingle } from 'services';
import { IndexedObject } from 'services/indexedObject';

import { API_URL } from 'settings';

import * as types from './types';
import {
  AssetEodPreview,
  ExecutionErrorType,
  ForwardContract,
  IndexFund,
  IndexFundPreview,
  MarketHoursType,
  OrderExecutionError,
  PartialOrderType,
  Player,
  PlayerWithDifferences,
  TriedAndFailedOrderType,
} from './types';
import { receiveCreatedAuctionType } from './auctions/types';
import { ClientMoney, ClientMoneyInitialState } from './client_money/types';

export type API<T> = {
  items: IndexedObject<T>;
  waiting: boolean;
  error?: any;
};

export type AppState = {
  badges: API<types.Badge>;
  klasses: API<types.Klass>;
  persons: API<types.Person>;
  sessions: API<types.Session>;
  paginated_sessions: {
    items: types.Session[];
    waiting: boolean;
    error?: unknown;
    page_number?: number;
    page_size?: number;
    count?: number;
  };
  auctions: receiveCreatedAuctionType[];
  active_session: number;
  selected_person: number;
  players: API<types.Player>;
  player: ApiStateSingle<types.Player>;
  underlying_assets: API<types.UnderlyingAsset> & { fetched: boolean };
  underlying_deposits: API<types.DepositUnderlying>;
  fx_cross: API<types.FXCross>;
  equities: API<types.Equity> & { fetched: boolean };
  dividends: API<types.Dividend> & { fetched: boolean };
  presets: API<types.Preset>;
  initial_presets: API<types.initialPreset>;
  currencies: API<types.Currency> & { reportingCurrency?: number };
  failedExecutions: OrderExecutionError[];
  orders: API<types.Order> & { last?: types.Order; fetching?: boolean };
  client_money: ClientMoney & { fetched: boolean; fetching?: boolean };
  // forward_contracts: API<types.ForwardContract> & {
  //   fetched: boolean;
  //   fetching?: boolean;
  // };
  index_funds: API<types.IndexFund>;
  news: API<types.News> & { last?: types.News[]; fetching?: boolean };
  initialPortfolioStatus: {
    [sessionId: number]: types.InitialBuyStatus;
  };
  individualReports: {
    [playerID: number]: types.IndividualReport;
  };
  correlationMatrix: ApiStateSingle<types.CorrelationMatrix> & {
    fetched: boolean;
  };
  correlationValues: API<types.CorrelationValue> & {
    fetched: boolean;
  };
  correlationValuesWaitingList: number[][];
  trainers: API<types.Trainer>;
  insightsReports: API<types.InsightsReport> & { creating: boolean };
  roadmapItems: API<types.RoadmapItem>;
  market_history: API<types.MarketHistory>;
  stock_splits: API<types.StockSplit> & { fetched: boolean };
  error?: string;
  admin: {
    betas: { [key: number]: types.EquityBeta };
    volatilities: { [key: number]: types.EquityVolatility };
    message?: string;
  };
  errors_tab: {
    execution_errors: {
      items: IndexedObject<ExecutionErrorType>;
      fetched: boolean;
      fetching?: boolean;
    };
    tried_and_failed_orders: {
      items: IndexedObject<TriedAndFailedOrderType>;
      fetched: boolean;
      fetching?: boolean;
    };
    players_with_differences: {
      items: IndexedObject<PlayerWithDifferences>;
      fetched: boolean;
      fetching?: boolean;
    };
    pending_moos: {
      items: IndexedObject<PartialOrderType>;
      fetched: boolean;
      fetching?: boolean;
    };
    moos_executed_today: {
      items: IndexedObject<PartialOrderType>;
      fetched: boolean;
      fetching?: boolean;
    };
    pending_sl_tps: {
      items: IndexedObject<PartialOrderType>;
      fetched: boolean;
      fetching?: boolean;
    };
  };
  historical_period_length: number;
  index_fund_preview: IndexFundPreview;
  asset_eod_preview: AssetEodPreview;
  market_hours: {
    items: IndexedObject<MarketHoursType>;
    fetched: boolean;
    fetching?: boolean;
  };
};

export const appManager = new StateManager({
  moduleName: 'app',
  apiUrl: API_URL,
  initialState: {
    badges: { items: {} },
    currencies: { items: {} },
    klasses: { items: {} },
    persons: { items: {} },
    orders: { items: {} },
    forward_contracts: { items: {}, fetched: false, fetching: false },
    client_money: ClientMoneyInitialState,
    players: { items: {} },
    player: { item: {} },
    sessions: { items: {} },
    auctions: { items: {} },
    paginated_sessions: { items: [] },
    underlying_assets: { items: {} },
    underlying_deposits: { items: {} },
    equities: { items: {} },
    fx_cross: { items: {} },
    dividends: { items: {} },
    index_funds: { items: {} },
    news: { items: {} },
    presets: { items: {} },
    initial_presets: { items: {} },
    initialPortfolioStatus: {},
    individualReports: {},
    insightsReports: { items: {}, creating: false },
    roadmapItems: { items: {} },
    trainers: { items: {} },
    correlationMatrix: { item: {} },
    correlationValues: { items: {} },
    market_history: { items: {} },
    stock_splits: { items: {} },
    correlationValuesWaitingList: [],
    admin: {
      beta: {},
      volatility: {},
      message: undefined,
    },
    errors_tab: {
      execution_errors: {
        items: {},
        fetched: false,
        fetching: false,
      },
      tried_and_failed_orders: {
        items: {},
        fetched: false,
        fetching: false,
      },
      players_with_differences: {
        items: {},
        fetched: false,
        fetching: false,
      },
      pending_moos: {
        items: {},
        fetched: false,
        fetching: false,
      },
      moos_executed_today: {
        items: {},
        fetched: false,
        fetching: false,
      },
      pending_sl_tps: {
        items: {},
        fetched: false,
        fetching: false,
      },
    },
    historical_period_length: 180,
  },
  tokenSelector: state => state.auth.item.token,
  selectors: [
    { varName: 'session_id', selector: state => state.app.active_session },
    {
      varName: 'person_id',
      selector: state => state.auth.item.selected_person,
    },
  ],
});
