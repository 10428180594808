import React from 'react';
import Equities from './Equities';
import { Typography } from 'antd';
import HistoricalPeriod from './HistoricalPeriod';
import AddInstrumentsToRunningSession from './AddInstrumentsToRunningSession';

const { Title } = Typography;

const Commands: React.FC = () => {
  return (
    <div>
      <Title level={3}>Equities</Title>
      <Equities />
      <br />
      <Title level={3}>Historical Period</Title>
      <HistoricalPeriod />
      <br />
      <Title level={3}>Add Instruments to Runnig Sessions</Title>
      <AddInstrumentsToRunningSession />
    </div>
  );
};
// deploy
export default Commands;
