import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSET_CLASSES,
  AssetClassOptions,
  MarketHoursType,
  Order,
  selectors,
  updateAssetClassOptions,
  updateMarketHours,
} from 'state';
import { Button, Input, InputNumber, Table, Typography } from 'antd';
import {
  marketHoursFetched,
  marketHoursItems,
} from '../../../state/app/selectors';
import { useModal } from 'services';
import CreateAssetModal from './AssetsTab/CreateAssetModal';
import CreateGeographyModal from './CreateGeographyModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';

type State = {
  [id: number]: MarketHoursType;
};

type Row = {
  geography: string;
  open_hours: string;
  closing_hours: string;
  metals_friday_close: string | undefined;
  metals_sunday_open: string | undefined;
};

type DataSource = {
  id: number;
  row: Row;
};

const MarketHoursTab: React.FC = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectors.marketHoursItems);
  const fetched = useSelector(selectors.marketHoursFetched);
  const [editing, setEditing] = useState(false);
  const [CreateModal, showModal] = useModal(CreateGeographyModal);
  const [hours, setHours] = useState<State>({});

  useEffect(() => {
    const newLimits = {} as State;
    data.forEach(item => {
      newLimits[item.id] = {
        id: item.id,
        geography: item.geography,
        open_hours: item.open_hours,
        closing_hours: item.closing_hours,
        metals_friday_close: item.metals_friday_close,
        metals_sunday_open: item.metals_sunday_open,
      };
    });
    setHours(newLimits);
  }, [data]);

  const dataSource = data.map(({ ...item }) => {
    const row = {
      geography: item.geography,
      open_hours: item.open_hours,
      closing_hours: item.closing_hours,
      metals_friday_close: item.metals_friday_close,
      metals_sunday_open: item.metals_sunday_open,
    };
    // const name = ASSET_CLASSES[item.asset_class];
    // const assetClass = item.asset_class;
    const id = item.id;
    return { id, row };
  });

  const reset = () => {
    const newLimits = {} as State;
    data.forEach(item => {
      newLimits[item.id] = {
        id: item.id,
        geography: item.geography,
        open_hours: item.open_hours,
        closing_hours: item.closing_hours,
        metals_friday_close: item.metals_friday_close,
        metals_sunday_open: item.metals_sunday_open,
      };
    });
    setHours(newLimits);
  };

  const getProps = (
    field:
      | 'geography'
      | 'open_hours'
      | 'closing_hours'
      | 'metals_friday_close'
      | 'metals_sunday_open'
  ) => {
    return {
      render(_: unknown, record: typeof dataSource[0]) {
        if (editing) {
          // const assetClass = record.asset_class;
          const schedule = hours[record.id];
          // const cell_value = schedule?.[field] ?? 0;
          const cell_value = schedule?.[field];
          const style =
            field === 'geography'
              ? { padding: 0 }
              : {
                  padding: 0,
                  textAlign: 'center' as const,
                  lineHeight: 'normal',
                };

          return (
            <div style={{ padding: 0 }}>
              <Input
                value={cell_value}
                style={style}
                onChange={value => {
                  if (value === undefined) {
                    return;
                  }
                  setHours({
                    ...hours,
                    [record.id]: {
                      ...schedule,
                      id: record.id,
                      [field]: value.target.value,
                    },
                  });
                }}
              />
            </div>
          );
        }

        const style2 =
          field === 'geography'
            ? { padding: 0 }
            : {
                padding: 0,
                textAlign: 'center' as const,
                lineHeight: 'normal',
              };
        // if (!record.row[field]) {
        //   return <div>-</div>;
        // }
        return <div style={style2}>{record.row[field]}</div>;
      },
    };
  };

  const editButton = (
    <div
    // style={{ position: 'relative', display: 'flex', gap: '10px', height: 30 }}
    >
      {!editing && (
        <Button
          onClick={() => {
            setEditing(true);
          }}
          // style={{ position: 'absolute', right: 0 }}
        >
          Edit
        </Button>
      )}
      {editing && (
        <>
          <Button
            onClick={() => {
              setEditing(false);
              dispatch(updateMarketHours({ market_hours: hours }));
              console.log('hours', hours);
            }}
            // style={{ position: 'absolute', right: 90 }}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setEditing(false);
              // reset();
            }}
            // style={{ position: 'absolute', right: 0 }}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        </>
      )}
    </div>
  );

  // const columns = [
  const columns: (ColumnGroupType<DataSource> | ColumnType<DataSource>)[] = [
    {
      title: 'ID',
      // dataIndex: 'id',
      render: (record: DataSource) => record.id,
      width: 30,
    },
    {
      title: 'Geography',
      ...getProps('geography'),
      // width: 150,
      sorter: (a: DataSource, b: DataSource) =>
        a.row.geography.localeCompare(b.row.geography),
      // sorter: (a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0),
    },
    {
      title: 'Open',
      ...getProps('open_hours'),
      width: 80,
      // align: 'centre',
    },
    {
      title: 'Close',
      ...getProps('closing_hours'),
      width: 80,
    },
    {
      title: 'Metals Sunday open',
      render: (record: DataSource) => {
        if (!record.row.metals_sunday_open) {
          return '--';
        } else {
          return record.row.metals_sunday_open;
        }
      },
      width: 150,
      align: 'center',
    },
    {
      title: 'Metals Friday close',
      render: (record: DataSource) => {
        if (!record.row.metals_friday_close) {
          return '--';
        } else {
          return record.row.metals_friday_close;
        }
      },
      width: 150,
      align: 'center',
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '10px',
        }}
      >
        <Button type={'primary'} onClick={() => showModal({})}>
          Create +
        </Button>
        {editButton}
      </div>
      <div>
        <ExclamationCircleOutlined style={{ marginRight: 10, color: 'red' }} />
        <Typography.Text type={'secondary'}>
          Only use UTC and 24-hour &apos;hh:mm&apos; format, otherwise scheduler
          could become unpredictable
        </Typography.Text>
      </div>
      <ExclamationCircleOutlined style={{ marginRight: 10, color: 'red' }} />
      <Typography.Text type={'secondary'}>
        Don&apos;t set to current UTC (avoid exactly matching hh:mm)
      </Typography.Text>
      <div style={{ maxHeight: '80vh' }}>
        <Table
          style={{ marginTop: 10, maxWidth: 650 }}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          scroll={{ y: '70vh' }}
        />
      </div>
      {CreateModal}
    </div>
  );
};

export default MarketHoursTab;
