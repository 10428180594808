import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Space, Button, Spin } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons/lib';

import { columnSearchWidget, LoadingScreen, TextShortener } from 'components';
import ETFDetailsModal from './ETFDetailsModal';
import BondDetailsModal from './BondDetailsModal';
import PriceCell from './PriceCell';

import {
  AssetClassOptions,
  domicilesArray,
  FinancialInstrument,
  HISTORICAL_SCENARIO,
  issuerTypeArray,
  REAL_TIME_SCENARIO,
  ReusableInstrumentTableType,
  selectors,
  UnderlyingAsset,
  utils,
} from 'state';
import { formatters } from 'services';
import {
  forwardInstrumetnReportingValue,
  tBillInstrumentReportingValue,
} from '../state/app/utils';
import { ColumnType } from 'antd/es/table';
import { fwdContractsIndexed } from '../state/app/selectors';
import { LoadingOutlined } from '@ant-design/icons';
import { auctions_tenors } from '../state/app/auctions/types';

type props = {
  financialInstruments: FinancialInstrument[];
  selectedInstrumentId?: number;
  selectInstrument?: (instrument: FinancialInstrument) => void;
  action?: (text: string, record: any, index: number) => React.ReactNode;
  marketHours?: boolean;
  priceArrows?: boolean;
  playerId: number | undefined;
  trainer_action?: (
    text: string,
    record: any,
    index: number
  ) => React.ReactNode;
};

const InstrumentTable: React.FC<props> = ({
  financialInstruments,
  selectedInstrumentId,
  action,
  marketHours,
  priceArrows,
  playerId,
  trainer_action,
}) => {
  const underlyingAssets = useSelector(selectors.underlyingAssets);
  const currencies = useSelector(selectors.currencies);
  const assetList = useSelector(selectors.underlyingAssetsList);
  const currencyList = useSelector(selectors.currenciesList);
  const contractsIndexed = useSelector(selectors.fwdContractsIndexed(playerId));
  // console.log('contractsIndexed', contractsIndexed);
  const contractsFetching = useSelector(
    selectors.fwdContractsFetching(playerId)
  );

  const session = useSelector(selectors.activeSession);
  const reportingCurrency = utils.useReportingCurrency(session.id);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showBondDetailsModal, setShowBondDetailsModal] = useState(false);
  const [instrument, setInstrument] = useState<FinancialInstrument>(
    financialInstruments[0]
  );
  const [detailsAssetId, setDetailsAssetId] = useState(0);

  const playableCurrencies = useSelector(
    selectors.playableCurrencies(financialInstruments)
  );

  const currencyFilters = playableCurrencies.map(symbol => {
    return {
      text: symbol,
      value: symbol,
    };
  });
  // console.log('instrument table');
  // console.log('contractsFetching: ', contractsFetching);
  // console.log(
  //   'resourceType: ',
  //   underlyingAssets[instrument.underlying_asset].resourcetype
  // );
  if (
    underlyingAssets[instrument.underlying_asset].resourcetype ===
      'FxForwards' &&
    contractsFetching
  ) {
    // console.log('entered loading');
    return (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 150 }} spin />} />
    );
  }

  const assetsToHide778: number[] = [1222, 1223, 1216];
  const assetsToHide779: number[] = [1266, 1269, 819];
  const dataSource = financialInstruments
    .filter(item => {
      if (
        session.id === 778 &&
        assetsToHide778.includes(item.underlying_asset)
      ) {
        return false;
      } else {
        return true;
      }
    })
    .filter(item => {
      if (
        session.id === 779 &&
        assetsToHide779.includes(item.underlying_asset)
      ) {
        return false;
      } else {
        return true;
      }
    })
    .map(item => ({
      ...item,
      ...underlyingAssets[item.underlying_asset],
      market_history: undefined,
      id: item.id,
    }))
    .map(item => {
      const [marketIsOpen, closedFromInterval] = utils.assetIsOpen(
        item as any,
        session
      );
      const currency = currencies[item.base_currency];

      const returnPercent = item.avg_open_price
        ? ((item.bid - item.avg_open_price) / item.avg_open_price) *
          Math.sign(item.amount)
        : 0;
      let reportingValue = utils.reportingValue(
        item as any,
        currency,
        item.resourcetype,
        assetList,
        currencyList,
        reportingCurrency
      );
      if (
        underlyingAssets[item.underlying_asset].resourcetype === 'FxForwards'
      ) {
        // reportingValue = 0;
        reportingValue = utils.forwardInstrumetnReportingValue(
          item,
          underlyingAssets[item.underlying_asset],
          session,
          assetList,
          currencyList,
          contractsIndexed
        );
        if (item.amount === 0) reportingValue = 0;
      } else if (
        underlyingAssets[item.underlying_asset].resourcetype === 'TBill'
      ) {
        reportingValue = utils.tBillInstrumentReportingValue(
          item,
          underlyingAssets[item.underlying_asset],
          session,
          assetList,
          currencyList
        );
      }
      return {
        ...item,
        currency: currency.symbol,
        return_percent: returnPercent,
        marketIsOpen,
        closedFromInterval,
        reportingValue,
      };
    })
    .filter(item => item.bid);

  const isETF = dataSource[0]?.resourcetype.includes('ETF');

  const bondsCashOrder = ['US', 'DE', 'FR', 'IT', 'UK', 'GB', 'JP', 'AU'];

  if (dataSource[0]?.resourcetype.endsWith('Bond')) {
    dataSource.sort((a, b) => {
      const tickerA = a.ticker;
      const tickerB = b.ticker;

      const indexA = bondsCashOrder.indexOf(tickerA.slice(0, 2));
      const indexB = bondsCashOrder.indexOf(tickerB.slice(0, 2));

      if (indexA != indexB) return indexA - indexB;

      return parseInt(tickerA.slice(2, 4)) - parseInt(tickerB.slice(2, 4));
    });
  }

  const fxOrder = [
    'EURUSD',
    'USDJPY',
    'GBPUSD',
    'USDCHF',
    'AUDUSD',
    'NZDUSD',
    'USDTRY',
    'USDCNY',
    'USDSGD',
  ];

  if (dataSource[0]?.resourcetype === 'FX') {
    dataSource.sort(
      (a, b) =>
        fxOrder.indexOf(a.name?.split(' ')[0]) -
        fxOrder.indexOf(b.name?.split(' ')[0])
    );
  }

  const extractFilters = (dataIndex: string) =>
    dataSource
      .map((item: any) => item[dataIndex])
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(g => ({ text: g, value: g }));

  let additionFields: any[] = [];
  let firstFields: any[] = [];
  if (dataSource[0]?.resourcetype === 'Equity') {
    firstFields = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
      },
      {
        title: 'Region',
        dataIndex: 'geography',
        key: 'geography',
        filters: extractFilters('geography'),
        onFilter: (value: any, record: any) => record.geography === value,
      },
      {
        title: 'BBG Ticker',
        dataIndex: 'ticker',
        key: 'ticker',

        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      },
    ];
  }

  if (
    dataSource[0]?.resourcetype === 'FX' ||
    dataSource[0]?.resourcetype === 'Metal'
  ) {
    firstFields = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
      },
      {
        title: 'BBG Ticker',
        dataIndex: 'ticker',
        key: 'ticker',

        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      },
    ];
  }

  if (dataSource[0]?.resourcetype === 'FxForwards') {
    firstFields = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
      },
      {
        title: 'BBG Ticker',
        dataIndex: 'ticker',
        key: 'ticker',

        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      },
      // {
      //   title: 'Open Date',
      //   dataIndex: 'open_date',
      //   key: 'open_date',
      //   // defaultSortOrder: 'descend',
      //   // sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      // },
      {
        title: 'Maturity Date',
        dataIndex: 'fwd_maturity_date',
        key: 'fwd_maturity_date',
        // defaultSortOrder: 'descend',
        // sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      },
    ];
    console.log('action: ', action);
    if (trainer_action !== undefined) {
      // meaning if this table is opened in trainer view (I don't like this reusable component)
      // additionFields = [
      //   {
      //     title: 'Action',
      //     key: 'action',
      //   },
      // ];
      additionFields.push({
        title: 'Action',
        render: trainer_action,
        fixed: 'right',
      });
    }
  }

  if (dataSource[0]?.resourcetype === 'Crypto') {
    firstFields = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
      },
      {
        title: 'BBG Ticker',
        dataIndex: 'ticker',
        key: 'ticker',

        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      },
    ];
  }

  if (isETF) {
    firstFields = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
        render: function Name(text: string, record: any) {
          return (
            <Space>
              <Button
                onClick={() => {
                  setShowDetailsModal(true);
                  setDetailsAssetId(record.underlying_asset);
                }}
              >
                <ZoomInOutlined />
              </Button>
              {TextShortener({ text, maxLength: 25 })}
            </Space>
          );
        },
      },
      {
        title: 'BBG Ticker',
        dataIndex: 'ticker',
        key: 'ticker',

        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      },
      // {
      //   title: 'Classification',
      //   dataIndex: 'classification',
      //   filters: extractFilters('classification'),
      //   onFilter: (value: any, record: any) => record.geography === value,
      // },
    ];
  }

  // if (dataSource[0]?.resourcetype.endsWith('Bond')) {
  if (
    dataSource[0]?.resourcetype == 'Bond' ||
    dataSource[0]?.resourcetype == 'CorporateBond' ||
    dataSource[0]?.resourcetype == 'GovtBond'
  ) {
    firstFields = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
        render(text: string, record: any) {
          return (
            <Space>
              {session.scenario_type === REAL_TIME_SCENARIO && (
                <Button
                  onClick={() => {
                    setDetailsAssetId(record.underlying_asset);
                    const selectedFin = financialInstruments.filter(fin => {
                      if (fin.id === record.id) return fin;
                    })?.[0];
                    setInstrument(selectedFin);
                    setShowBondDetailsModal(true);
                  }}
                >
                  <ZoomInOutlined />
                </Button>
              )}
              {TextShortener({ text, maxLength: 25 })}
            </Space>
          );
        },
      },
      {
        title: 'BBG Ticker',
        dataIndex: 'ticker',
        key: 'ticker',

        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      },
    ];
    additionFields = [
      {
        title: 'Coupon Date',
        dataIndex: 'coupon_date',
        key: 'coupon_date',
        align: 'right',

        // sorter: (a: any, b: any) => a.coupon_date.localeCompare(b.coupon_date),
        // defaultSortOrder: 'descend',
      },
      // {
      //   title: 'Acc. Days',
      //   dataIndex: 'accrued_days',
      //   key: 'accrued_days',
      //   align: 'right',
      //
      //   sorter: (a: any, b: any) => a.accrued_days - b.accrued_days,
      //   // render: formatters.commas2Digits,
      // },
      {
        title: 'Coupon %',
        dataIndex: 'coupon_rate',
        key: 'coupon_rate',
        align: 'right',

        sorter: (a: any, b: any) => a.coupon_rate - b.coupon_rate,
        render: formatters.commas3Digits,
      },
      {
        title: 'DV01',
        dataIndex: 'bpv',
        key: 'bpv',
        align: 'right',

        sorter: (a: any, b: any) => a.bpv - b.bpv,
        render: formatters.commas2Digits,
      },
      {
        title: '~Duration',
        dataIndex: 'duration',
        key: 'duration',
        align: 'right',

        sorter: (a: any, b: any) => a.duration - b.duration,
        render: formatters.commas2Digits,
      },
      {
        title: 'Yield',
        dataIndex: 'ask_yield',
        key: 'ask_yield',
        align: 'right',

        sorter: (a: any, b: any) => a.ask_yield - b.ask_yield,
        render: (a: number) => formatters.commas3Digits(a) + ' %',
      },
      {
        title: 'Rating',
        dataIndex: 'rating',
        render: (rating: string) => rating || '-',
      },
    ].filter(
      col =>
        !(
          ['~Duration', 'DV01', 'Coupon %', 'Coupon Date'].includes(
            col.title
          ) && session.scenario_type === HISTORICAL_SCENARIO
        )
    );
  }

  let unrealizedOrMTM = 'Unrealized, ';

  if (
    dataSource[0]?.resourcetype == 'HTMBond' ||
    dataSource[0]?.resourcetype == 'AFSBond'
  ) {
    if (dataSource[0]?.resourcetype == 'HTMBond') {
      unrealizedOrMTM = 'MTM, ';
    }
    firstFields = [
      {
        title: 'Name',
        dataIndex: 'name',
        fixed: 'left',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
        render(text: string, record: any) {
          return (
            <Space>
              {session.scenario_type === REAL_TIME_SCENARIO && (
                <Button
                  onClick={() => {
                    setDetailsAssetId(record.underlying_asset);
                    const selectedFin = financialInstruments.filter(fin => {
                      if (fin.id === record.id) return fin;
                    })?.[0];
                    setInstrument(selectedFin);
                    setShowBondDetailsModal(true);
                  }}
                >
                  <ZoomInOutlined />
                </Button>
              )}
              {TextShortener({ text, maxLength: 25 })}
            </Space>
          );
        },
      },
      {
        title: 'Issuer Type',
        dataIndex: 'issuer_type',
        key: 'issuer_type',
        // filters: Object.entries(currencies).map(([id, currency]) => {
        filters: issuerTypeArray.map(type => {
          return {
            text: type,
            value: type,
            // value: parseInt(id),
          };
        }),
        onFilter: (value: string, record: UnderlyingAsset) => {
          return value === record.issuer_type;
        },
        sorter: (a: UnderlyingAsset, b: UnderlyingAsset) => {
          const stringA = a.domicile ?? '';
          const stringB = b.domicile ?? '';
          return stringA.localeCompare(stringB);
        },
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Domicile',
        dataIndex: 'domicile',
        key: 'domicile',
        // filters: Object.entries(currencies).map(([id, currency]) => {
        filters: domicilesArray.map(domicile => {
          return {
            text: domicile,
            value: domicile,
            // value: parseInt(id),
          };
        }),
        onFilter: (value: string, record: UnderlyingAsset) => {
          return value === record.domicile;
        },
        sorter: (a: UnderlyingAsset, b: UnderlyingAsset) => {
          const stringA = a.domicile ?? '';
          const stringB = b.domicile ?? '';
          return stringA.localeCompare(stringB);
        },
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Years To Mat.',
        dataIndex: 'htm_afs_years_to_maturity',
        key: 'htm_afs_years_to_maturity',
        align: 'right',

        sorter: (a: any, b: any) =>
          a.htm_afs_years_to_maturity - b.htm_afs_years_to_maturity,
        render: formatters.commas2Digits,
        defaultSortOrder: 'ascend',
      },
      {
        title: '~Duration',
        dataIndex: 'htm_afs_duration',
        key: 'htm_afs_duration',
        align: 'right',

        sorter: (a: any, b: any) => a.htm_afs_duration - b.htm_afs_duration,
        render: formatters.commasExact3Digits,
      },
      {
        title: 'Coupon %',
        dataIndex: 'coupon',
        key: 'coupon',
        align: 'right',

        sorter: (a: any, b: any) => a.coupon - b.coupon,
        render: formatters.commasExact3Digits,
      },
      {
        title: 'Yield',
        dataIndex: 'ask_yield',
        key: 'ask_yield',
        align: 'right',

        sorter: (a: any, b: any) => a.ask_yield - b.ask_yield,
        render: (a: number) => formatters.commas3Digits(a) + ' %',
      },
      {
        title: 'DV01',
        dataIndex: 'htm_afs_dv01',
        key: 'htm_afs_dv01',
        align: 'right',

        sorter: (a: any, b: any) => a.htm_afs_dv01 - b.htm_afs_dv01,
        render: formatters.commas2Digits,
      },
    ];
    additionFields = [
      {
        title: 'Days to coupon',
        dataIndex: 'htm_afs_days_to_coupon',
        key: 'htm_afs_days_to_coupon',
        align: 'right',

        sorter: (a: any, b: any) =>
          a.htm_afs_days_to_coupon - b.htm_afs_days_to_coupon,
        // defaultSortOrder: 'descend',
        render: (value: number) => {
          if (value === 0.1) {
            return '--';
          } else {
            return value;
          }
        },
      },
      // {
      //   title: 'Rating',
      //   dataIndex: 'rating',
      //   render: (rating: string) => rating || '-',
      // },
    ].filter(
      col =>
        !(
          [''].includes(col.title) &&
          session.scenario_type === HISTORICAL_SCENARIO
        )
    );
  }

  if (action) {
    additionFields.push({ title: 'Action', render: action, fixed: 'right' });
  }

  // console.log('dataSource from instrument table: ', dataSource);

  // if (dataSource[0]?.resourcetype === 'Repo') {
  //   firstFields = [
  //     {
  //       title: 'Name',
  //       dataIndex: 'name',
  //       ...columnSearchWidget({
  //         dataIndex: 'name',
  //       }),
  //     },
  //     {
  //       title: 'BBG Ticker',
  //       dataIndex: 'ticker',
  //       key: 'ticker',
  //     },
  //   ];
  // }
  if (dataSource[0]?.resourcetype === 'TBill') {
    firstFields = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
      },
      // {
      //   title: 'Region',
      //   dataIndex: 'geography',
      //   key: 'geography',
      //   filters: extractFilters('geography'),
      //   onFilter: (value: any, record: any) => record.geography === value,
      // },
      // {
      //   title: 'BBG Ticker',
      //   dataIndex: 'ticker',
      //   key: 'ticker',
      //
      //   defaultSortOrder: 'descend',
      //   sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      // },
      {
        title: 'Tenor',
        dataIndex: 'tbill_tenor',
        key: 'tbill_tenor',
        render: (value: number) => {
          return auctions_tenors[value];
        },
      },
      // {
      //   title: 'Rate',
      //   dataIndex: 'tbill_rate',
      //   key: 'tbill_rate',
      //   // render: (value: number) => {
      //   //   return auctions_tenors[value];
      //   // },
      // },
    ];
  }

  let columns: ColumnType<any>[];
  if (dataSource[0]?.resourcetype == 'Repo') {
    columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
      },
      {
        title: 'BBG Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
      },
      {
        title: 'CCY',
        dataIndex: 'currency',
        key: 'currency',
        filters: currencyFilters,
        onFilter: (value: any, record: ReusableInstrumentTableType) =>
          record.currency === value,
      },
      // {
      //   title: 'BID',
      //   dataIndex: 'bid',
      //   key: 'bid',
      //   align: 'right',
      //
      //   sorter: (a: any, b: any) => a.bid - b.bid,
      //   // render: function Price(val: any, record: ReusableInstrumentTableType) {
      //   //   const price = formatters.priceFormat(
      //   //     val,
      //   //     record.ticker,
      //   //     record.resourcetype
      //   //   );
      //   //
      //   //   if (!priceArrows) return price;
      //   //
      //   //   return <PriceCell priceDiff={record.bid_diff} price={price} />;
      //   // },
      //   render: function RepoBid(
      //     val: any,
      //     record: ReusableInstrumentTableType
      //   ) {
      //     return <div>{val} %</div>;
      //   },
      // },
      {
        title: 'ASK',
        dataIndex: 'ask',
        key: 'ask',
        align: 'right',

        sorter: (a: any, b: any) => a.ask - b.ask,
        // render: function Price(val: any, record: ReusableInstrumentTableType) {
        //   return (
        //     <PriceCell
        //       priceDiff={record.ask_diff}
        //       price={formatters.priceFormat(
        //         val,
        //         record.ticker,
        //         record.resourcetype
        //       )}
        //     />
        //   );
        // },
        render: function RepoAsk(
          val: any,
          record: ReusableInstrumentTableType
        ) {
          return <div>{formatters.commasExact2Digits(val)} %</div>;
        },
      },
    ];

    if (session.scenario_type === REAL_TIME_SCENARIO) {
      columns.push({
        title: 'Maturity',
        dataIndex: 'repo_maturity_date',
        key: 'repo_maturity_date',
        render: (val: string, record: ReusableInstrumentTableType) => {
          return <div>{val.split('T')[0]}</div>;
        },
      });
    }
    columns.push({ title: 'Action', render: action, fixed: 'right' });
  } else if (dataSource[0]?.resourcetype === 'CDS') {
    columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        ...columnSearchWidget({
          dataIndex: 'name',
        }),
      },
      {
        title: 'BBG Ticker',
        dataIndex: 'ticker',
        key: 'ticker',

        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      },
      // {
      //   title: 'Open Date',
      //   dataIndex: 'open_date',
      //   key: 'open_date',
      //   // defaultSortOrder: 'descend',
      //   // sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      // },
      {
        title: 'Maturity',
        dataIndex: 'cds_asset_maturity_date',
        key: 'cds_asset_maturity_date',
        // defaultSortOrder: 'descend',
        // sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
        render: (val: string) => {
          return <div>{val.split('T')[0]}</div>;
        },
      },
      {
        title: 'BID',
        dataIndex: 'bid',
        key: 'bid',
        align: 'right',

        sorter: (a: any, b: any) => a.bid - b.bid,
        render: function Price(val: any, record: ReusableInstrumentTableType) {
          const price = formatters.priceFormat(
            val,
            record.ticker,
            record.resourcetype
          );

          if (!priceArrows) return price;

          return <PriceCell priceDiff={record.bid_diff} price={price} />;
        },
      },
      {
        title: 'ASK',
        dataIndex: 'ask',
        key: 'ask',
        align: 'right',

        sorter: (a: any, b: any) => a.ask - b.ask,
        render: function Price(val: any, record: ReusableInstrumentTableType) {
          return (
            <PriceCell
              priceDiff={record.ask_diff}
              price={formatters.priceFormat(
                val,
                record.ticker,
                record.resourcetype
              )}
            />
          );
        },
      },
      {
        title: 'CCY',
        dataIndex: 'currency',
        key: 'currency',
        filters: currencyFilters,
        onFilter: (value: any, record: ReusableInstrumentTableType) =>
          record.currency === value,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',

        sorter: (a: any, b: any) => a.amount - b.amount,
        render: formatters.commasNoDigits,
      },
      {
        title: `${unrealizedOrMTM}` + reportingCurrency.symbol,
        // for bonds this is clean (trading) unrealized PnL, without unrealized interest added
        dataIndex: 'reporting_unrealized_pnl',
        align: 'right',

        sorter: (a: any, b: any) =>
          a.reporting_unrealized_pnl - b.reporting_unrealized_pnl,
        render: formatters.commasNoDigits,
      },
      {
        title: 'Realized, ' + reportingCurrency.symbol,
        dataIndex: 'reporting_realized_pnl',
        align: 'right',

        sorter: (a: any, b: any) =>
          a.reporting_realized_pnl - b.reporting_realized_pnl,
        render: formatters.commasNoDigits,
      },
      // {
      //   title: 'Value, ' + reportingCurrency.symbol,
      //   // for bonds this is clean open value (amount * clean open price)
      //   dataIndex: 'reportingValue',
      //   key: 'reportingValue',
      //   align: 'right',
      //   sorter: (a: any, b: any) => a.reportingValue - b.reportingValue,
      //   render: formatters.commasNoDigits,
      // },
      { title: 'Action', render: action, fixed: 'right' },
    ];
  } else {
    columns = [
      ...firstFields,
      // {
      //   title: 'BBG Ticker',
      //   dataIndex: 'ticker',
      //   key: 'ticker',
      //
      //   defaultSortOrder: 'descend',
      //   sorter: (a: any, b: any) => (a.ticker > b.ticker ? -1 : 1),
      // },
      {
        title: 'BID',
        dataIndex: 'bid',
        key: 'bid',
        align: 'right',

        sorter: (a: any, b: any) => a.bid - b.bid,
        render: function Price(val: any, record: ReusableInstrumentTableType) {
          const price = formatters.priceFormat(
            val,
            record.ticker,
            record.resourcetype
          );

          if (!priceArrows) return price;

          return <PriceCell priceDiff={record.bid_diff} price={price} />;
        },
      },
      {
        title: 'ASK',
        dataIndex: 'ask',
        key: 'ask',
        align: 'right',

        sorter: (a: any, b: any) => a.ask - b.ask,
        render: function Price(val: any, record: ReusableInstrumentTableType) {
          return (
            <PriceCell
              priceDiff={record.ask_diff}
              price={formatters.priceFormat(
                val,
                record.ticker,
                record.resourcetype
              )}
            />
          );
        },
      },
      {
        title: 'CCY',
        dataIndex: 'currency',
        key: 'currency',
        filters: currencyFilters,
        onFilter: (value: any, record: ReusableInstrumentTableType) =>
          record.currency === value,
      },
      {
        title: 'Open',
        dataIndex: 'avg_open_price',
        key: 'avg_open_price',
        align: 'right',

        sorter: (a: any, b: any) => a.avg_open_price - b.avg_open_price,
        render: (val: any, record: ReusableInstrumentTableType) =>
          formatters.priceFormat(val, record.ticker, record.resourcetype),
      },
      // de aici
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',

        sorter: (a: any, b: any) => a.amount - b.amount,
        render: formatters.commasNoDigits,
      },
      {
        title: `${unrealizedOrMTM}` + reportingCurrency.symbol,
        // for bonds this is clean (trading) unrealized PnL, without unrealized interest added
        dataIndex: 'reporting_unrealized_pnl',
        align: 'right',

        sorter: (a: any, b: any) =>
          a.reporting_unrealized_pnl - b.reporting_unrealized_pnl,
        render: formatters.commasNoDigits,
      },
      {
        title: 'Realized, ' + reportingCurrency.symbol,
        dataIndex: 'reporting_realized_pnl',
        align: 'right',

        sorter: (a: any, b: any) =>
          a.reporting_realized_pnl - b.reporting_realized_pnl,
        render: formatters.commasNoDigits,
      },
      {
        title: 'Value, ' + reportingCurrency.symbol,
        // for bonds this is clean open value (amount * clean open price)
        dataIndex: 'reportingValue',
        key: 'reportingValue',
        align: 'right',
        sorter: (a: any, b: any) => a.reportingValue - b.reportingValue,
        render: formatters.commasNoDigits,
      },
      // {
      //   title: 'Return %',
      //   dataIndex: 'return_percent',
      //   key: 'return_percent',
      //   align: 'right',
      //
      //   sorter: (a: any, b: any) => a.return_percent - b.return_percent,
      //   render: function Return(n, record) {
      //     const val = formatters.percent1Digit(n);
      //     return (
      //       <span
      //         style={
      //           record.marketIsOpen
      //             ? { color: n < 0 ? 'red' : 'green' }
      //             : {}
      //         }
      //       >
      //         {val}
      //       </span>
      //     );
      //   },
      // },
      ...additionFields,
    ];
  }

  return (
    <div>
      <Table
        rowKey="id"
        bordered
        dataSource={dataSource}
        pagination={false}
        //TODO: vertical scrolling so header sticks scroll={{ x: 3000, y: 300 }}
        {...({
          rowClassName: (record: any) => {
            if (marketHours && !record.marketIsOpen) return 'disabled-row';
            if (record.id === selectedInstrumentId) return 'selected-row';
          },
        } as any)}
        columns={columns}
      />
      {showDetailsModal && (
        <ETFDetailsModal
          assetId={detailsAssetId}
          visible={showDetailsModal}
          hide={() => setShowDetailsModal(false)}
        />
      )}
      {showBondDetailsModal && (
        <BondDetailsModal
          assetId={detailsAssetId}
          visible={showBondDetailsModal}
          instrument={instrument}
          hide={() => setShowBondDetailsModal(false)}
        />
      )}
    </div>
  );
};

export default InstrumentTable;
