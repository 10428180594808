import { appManager, AppState } from '../manager';
import { arrayToIndexedObject, makeAPIProp } from 'services/indexedObject';

import * as types from '../types';
import {
  InterBankDeal,
  MetricsSocketResult,
  PlayerHistorySocketResult,
  RFQRequest,
  RFQResponse,
  Wallet,
} from '../types';
import { notification } from 'antd';
import { formatters } from 'services';
// import app from '../../../App';

export const updateInvestmentProfile = appManager.createApi<
  { data: types.InvestmentProfile },
  types.InvestmentProfile,
  AppState
>('UPDATE_INVESTMENT_PROFILE', {
  path: '/players/update_investment_profile',
  method: 'POST',
  successReducer(state, result) {
    state.player.item.investment_profile = result;
  },
});
export const makeOrBreakDeposit = appManager.createApi<
  {
    wallet_id: number;
    deposit: number;
  },
  types.Wallet,
  AppState
>('MAKE_OR_BREAK_DEPOSIT', {
  path: '/wallets/:wallet_id/make_or_break_deposit',
  method: 'POST',
  successReducer(state, result) {
    const index = state.player.item.wallets.findIndex(
      item => item.id === result.id
    );
    state.player.item.wallets[index] = result;
  },
});

export const exchangeMoney = appManager.createApi<
  { from_wallet: number; to_wallet: number; amount: number },
  { wallets: types.Wallet[]; exchange_history: types.ExchangeHistory },
  AppState
>('EXCHANGE_MONEY', {
  path: '/wallets/exchange',
  method: 'POST',
  successReducer(state, result) {
    result.wallets.forEach(wallet => {
      const index = state.player.item.wallets.findIndex(
        item => item.id === wallet.id
      );

      state.player.item.wallets[index] = wallet;
    });
    const player = state.player.item;
    if (!player.exchange_history) player.exchange_history = [];

    player.exchange_history.push(result.exchange_history);
  },
});

export const updateSAL = appManager.createApi<
  types.UpdateSALPayload,
  types.StrategicAssetAllocation[],
  AppState
>('UPDATE_SAL', {
  path: '/players/set_strategic_asset_allocations',
  method: 'POST',
  successReducer(state, result) {
    state.player.item.strategic_asset_allocations = result;
  },
});

export const updatePlayerSettings = appManager.createApi<
  { name: string },
  { name: string },
  AppState
>('UPDATE_PLAYER_Settings', {
  path: '/players/update_settings',
  method: 'POST',
  successReducer(state, result) {
    state.player.item = { ...state.player.item, ...result };
  },
});

export const fetchBlotter = appManager.createApi<
  unknown,
  {
    orders: types.Order[];
    exchange_history: types.ExchangeHistory[];
    deposits_history: types.DepositHistory[];
  },
  AppState
>('FETCH_BLOTTER', {
  path: '/players/get_blotter',
  method: 'GET',
  startReducer(state) {
    state.player.item.fetching_blotter = true;
    state.player.item.fetched_blotter = false;
    state.orders.fetching = true;
  },
  successReducer(state, result) {
    state.orders = makeAPIProp(result.orders);
    state.player.item.exchange_history = result.exchange_history;
    state.player.item.deposits_history = result.deposits_history;
    state.player.item.fetching_blotter = false;
    state.player.item.fetched_blotter = true;
    state.orders.fetching = false;
  },
  failReducer(state) {
    state.player.item.fetching_blotter = false;
    state.orders.fetching = false;
  },
});

export const fetchForwardContracts = appManager.createApi<
  { session_id: number },
  {
    fwd_contracts: types.ForwardContract[];
    fwd_odas: types.OdaForward[];
  },
  AppState
>('FETCH_FWD_CONTRACTS', {
  path: '/players/get_fwd_contracts',
  method: 'POST',
  startReducer(state) {
    // initialize
    state.player.item.forward_contracts = {
      items: {},
      fetching: true,
      fetched: false,
    };
    state.player.item.forward_oda = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.player.item.forward_contracts = makeAPIProp(result.fwd_contracts);
    state.player.item.forward_contracts.fetching = false;
    state.player.item.forward_contracts.fetched = true;

    state.player.item.forward_oda = makeAPIProp(result.fwd_odas);
    state.player.item.forward_oda.fetching = false;
    state.player.item.forward_oda.fetched = true;
  },
  failReducer(state) {
    state.player.item.forward_contracts.fetching = false;
    state.player.item.forward_contracts.fetched = false;

    state.player.item.forward_oda.fetching = false;
    state.player.item.forward_oda.fetched = false;
  },
});

export const fetchForwardContractsByPlayerId = appManager.createApi<
  { player_id: number },
  {
    fwd_contracts: types.ForwardContract[];
    player_id_received: number;
  },
  AppState
>('FETCH_FWD_CONTRACTS_BY_PLAYER_ID', {
  path: '/players/get_fwd_contracts_by_player_id',
  method: 'POST',
  startReducer(state, payload) {
    // initialize
    state.players.items[payload.player_id].forward_contracts = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result, payload) {
    state.players.items[
      payload.player_id
    ].forward_contracts.items = arrayToIndexedObject(result.fwd_contracts);
    state.players.items[payload.player_id].forward_contracts.fetching = false;
    state.players.items[payload.player_id].forward_contracts.fetched = true;
  },
  failReducer(state, error, payload) {
    state.players.items[payload.player_id].forward_contracts.fetching = false;
    state.players.items[payload.player_id].forward_contracts.fetched = false;
  },
});

// export const invalidateForwardContracts = appManager.createLocalEvent(
//   'invalidate_forward_contracts',
//   (state, payload) => {
//     state.forward_contracts.fetching = false;
//     state.forward_contracts.fetched = false;
//     state.forward_contracts.items = undefined;
//
//   }
// );

export const initializeForwardContractsState = appManager.createLocalEvent(
  'initialize_forward_contracts_state',
  (state, payload) => {
    const player = state.players.items[payload.player_id];
    if (player?.forward_contracts?.fetched) {
      // don't initialize with no data if data already present
    } else {
      state.players.items[payload.player_id].forward_contracts = {
        items: {},
        fetching: true,
        fetched: false,
      };
    }
  }
);

appManager.createSocketListener<types.TacticalAssetAllocation[], AppState>(
  'tactical_asset_allocation_received',
  (state, result) => {
    state.player.item.tactical_asset_allocations = result;
  }
);

appManager.createSocketListener<types.RegionAllocation[], AppState>(
  'region_allocations_received',
  (state, result) => {
    state.player.item.region_allocations = result;
  }
);

appManager.createSocketListener<types.MetricsSocketResult, AppState>(
  'metrics_received',
  (state, result) => {
    if (state.player.item.id === undefined) {
      // CASE: window is in trainer view
      const playerId = result.player;
      delete result.player;
      state.players.items[playerId]?.metrics.push(result);
    } else {
      // CASE: window is in trainee view
      if (result.player === state.player.item.id) {
        state.player.item.metrics?.push(result);
      }
    }
  }
);

appManager.createSocketListener<types.PlayerBadge, AppState>(
  'badge_unlocked',
  (state, result) => {
    state.player.item.badges.push(result);
    const badge = state.badges.items[result.badge];

    state.player.item.coins += badge.coins;
  }
);

appManager.createSocketListener<
  {
    market_value: number;
    remaining_short_sell_amount_limit: number;
    remaining_fx_amount_limit: number;
    risk_weighted_value: number;
    book_value: number;
    portfolio_return: number;
    fx_return: number;
    fx_return_percent: number;
    htm_portfolio_dv01: number;
    htm_portfolio_duration: number;
    afs_portfolio_dv01: number;
    afs_portfolio_duration: number;
  },
  AppState
>('portfolio_value_updated', (state, result) => {
  // console.log('portfolio_value_updated: ', result);
  state.player.item = { ...state.player.item, ...result };
});

appManager.createSocketListener<types.PlayerHistorySocketResult, AppState>(
  'player_history_created',
  (state, result) => {
    // console.log('player_history_created: ', result);
    if (state.player.item.id === undefined) {
      // CASE: window is in trainer view
      const playerId = result.player;
      delete result.player;
      state.players.items[playerId]?.player_history?.push(result);
    } else {
      // CASE: window is in trainee view
      if (result.player === state.player.item.id) {
        state.player.item.player_history?.push(result);
      }
    }
  }
);

appManager.createSocketListener<{ id: number }, AppState>(
  'player_connected',
  (state, result) => {
    const player = state.players.items[result.id];
    if (!player) return;
    player.online = true;
  }
);

appManager.createSocketListener<{ id: number }, AppState>(
  'player_disconnected',
  (state, result) => {
    const player = state.players.items[result.id];
    if (!player) return;
    player.online = false;
  }
);

export const updatePlayerBenchmarkComposition = appManager.createApi<
  { composition?: types.BenchmarkComposition[]; player_id: number },
  { composition: types.BenchmarkComposition[] },
  AppState
>('UPDATE_PLAYER_BENCHMARK_COMPOSITION', {
  path: '/players/:player_id/update_benchmark_composition',
  method: 'POST',
  successReducer(state, result) {
    state.player.item.benchmark_compositions = result.composition;
  },
});

appManager.createSocketListener<
  {
    financial_instruments: {
      id: number;
      pv_limit: number;
      risk_weight: number;
    }[];
  },
  AppState
>('update_instruments_options', (state, result) => {
  const instruments = state.player.item.financial_instruments;
  result.financial_instruments.forEach(({ id, risk_weight, pv_limit }) => {
    instruments[id].risk_weight = risk_weight;
    instruments[id].pv_limit = pv_limit;
  });
});

appManager.createSocketListener<
  {
    fins: {
      id: number;
      reporting_realized_pnl: number;
      reporting_unrealized_pnl: number;
      amount: number;
      available_repoable_amount: number;
      repoed_amount: number;
    }[];
  },
  // unknown,
  // UpdateFins[],
  AppState
>('update_instruments', (state, result) => {
  // console.log('update_instruments results:', result);
  // const instruments = state.player.item.financial_instruments;
  // console.log('UPDATE_INSTRUMENTS result', result);
  // result?.fins?.forEach(
  //   ({
  //     id,
  //     reporting_realized_pnl,
  //     reporting_unrealized_pnl,
  //     amount,
  //     available_repoable_amount,
  //   }) => {
  //     instruments[id].reporting_realized_pnl = reporting_realized_pnl;
  //     instruments[id].reporting_unrealized_pnl = reporting_unrealized_pnl;
  //     instruments[id].amount = amount;
  //     instruments[id].available_repoable_amount = available_repoable_amount;
  //   }
  // );

  const instruments = state.player.item.financial_instruments;
  // console.log('UPDATE_INSTRUMENTS result', result);

  const updatedInstruments = { ...instruments };

  // Update each instrument in the shallow copy
  result?.fins?.forEach(
    ({
      id,
      reporting_realized_pnl,
      reporting_unrealized_pnl,
      amount,
      available_repoable_amount,
      repoed_amount,
    }) => {
      updatedInstruments[id] = {
        ...instruments[id],
        reporting_realized_pnl,
        reporting_unrealized_pnl,
        amount,
        available_repoable_amount,
        repoed_amount,
      };
    }
  );

  // Mutate the state by assigning the updated instruments
  state.player.item.financial_instruments = updatedInstruments;
});

appManager.createSocketListener<
  Wallet[],
  // unknown,
  // UpdateFins[],
  AppState
>('update_wallets', (state, result) => {
  const wallets = state.player.item.wallets;
  // console.log('wallets result: ', result);
  result.forEach(updated_wallet => {
    const wallet = wallets.find(wallet => wallet.id === updated_wallet.id);
    if (wallet !== undefined) {
      wallet.amount = updated_wallet.amount;
      wallet.collateral_amount = updated_wallet.collateral_amount;
      wallet.client_money = updated_wallet.client_money;
      wallet.client_interest = updated_wallet.client_interest;
      wallet.lombard_interest = updated_wallet.lombard_interest;
    }
  });
});

appManager.createSocketListener<
  {
    instrument_id: number;
    collateral: number;
  },
  // unknown,
  // UpdateFins[],
  AppState
>('update_instrument_collateral', (state, result) => {
  state.player.item.financial_instruments[result.instrument_id].collateral =
    result.collateral;
});
// commented because this would trigger an S3 file fetch and currently boto3 S3 access is deactivated in backend
export const retrieveInsightsReports = appManager.createApi<
  { player_id: number },
  types.InsightsReport[],
  AppState
>('RETRIEVE_INSIGHTS_REPORTS', {
  path: '/players/:player_id/insights_reports',
  method: 'GET',
  successReducer(state, result) {
    result.forEach(report => {
      state.insightsReports.items[report.id] = report;
    });

    const player = state.player.item;
    player.insights_reports = result.map(r => r.id);
  },
});

// commented because this would trigger an S3 file fetch and currently boto3 S3 access is deactivated in backend
export const listRoadmapItems = appManager.createApi<
  { player_id: number },
  types.RoadmapItem[],
  AppState
>('LIST_ROADMAP_ITEMS', {
  path: '/players/:player_id/roadmap_items',
  method: 'GET',
  successReducer(state, result) {
    result.forEach(item => {
      state.roadmapItems.items[item.id] = item;
    });
  },
});

export const updatePortfolioName = appManager.createApi<
  { player_id: number; portfolio_name: string },
  { portfolio_name: string },
  AppState
>('UPDATE_PORTFOLIO_NAME', {
  path: '/players/:player_id/update_portfolio_name',
  method: 'POST',
  successReducer(state, result) {
    state.player.item.portfolio_name = result.portfolio_name;
    // if (state.players.items[payload.player_id]) {
    //   state.players.items[payload.player_id].portfolio_name =
    //     result.portfolio_name;
    // }
  },
});

export const sendRFQ = appManager.createApi<
  { amount: number; player_id: number; selected_players: number[] },
  { new_rfq: RFQRequest },
  AppState
>('SEND_RFQ', {
  path: '/players/send_rfq',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
    if (state.player.item.sent_rfq_requests === undefined) {
      state.player.item.sent_rfq_requests = [];
    }
    state.player.item.sent_rfq_requests.push(result.new_rfq);
  },
});

export const cancelRFQ = appManager.createApi<
  { request_id: number },
  // { updated_rfq: RFQRequest },
  unknown,
  AppState
>('CANCEL_RFQ', {
  path: '/players/cancel_rfq',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
    // const requestIndex = state.player.item.sent_rfq_requests.findIndex(
    //   request => request.id === result.updated_rfq.id
    // );
    // state.player.item.sent_rfq_requests[requestIndex] = result.updated_rfq;
  },
});

export const hitRFQResponse = appManager.createApi<
  { response_id: number; type: number },
  // { updated_rfq: RFQRequest },
  unknown,
  AppState
>('HIT_RFQ_RESPONSE', {
  path: '/players/hit_rfq_response',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
    // const requestIndex = state.player.item.sent_rfq_requests.findIndex(
    //   request => request.id === result.updated_rfq.id
    // );
    // state.player.item.sent_rfq_requests[requestIndex] = result.updated_rfq;
  },
});

appManager.createSocketListener<
  {
    updated_response: RFQResponse;
    interbank_deal?: InterBankDeal;
    updated_wallet?: Wallet;
    new_mkt_val?: number;
  },
  // unknown,
  // UpdateFins[],
  AppState
>('response_got_hit', (state, result) => {
  if (result.updated_response.status === 'nothing there') {
    notification.info({
      message: `New quote answer: nothing there!`,
      description: `From ${
        result.updated_response.requesting_player_name
      }; Amount: ${formatters.commasNoDigits(result.updated_response.amount)};
      Quote: ${formatters.commasExact2Digits(
        result.updated_response.bid
      )}% | ${formatters.commasExact2Digits(result.updated_response.ask)}%`,
      duration: 5,
    });
  } else if (result.updated_response.status === 'borrow') {
    notification.info({
      message: `Quote hit!`,
      description: `${
        result.updated_response.requesting_player_name
      } borrowed ${formatters.commasNoDigits(
        result.updated_response.amount
      )}; Quote: ${formatters.commasExact2Digits(
        result.updated_response.bid
      )}% | ${formatters.commasExact2Digits(result.updated_response.ask)}%`,
    });
  } else {
    notification.info({
      message: `Quote hit!`,
      description: `${
        result.updated_response.requesting_player_name
      } lent you ${formatters.commasNoDigits(
        result.updated_response.amount
      )}; Quote: ${formatters.commasExact2Digits(
        result.updated_response.bid
      )}% | ${formatters.commasExact2Digits(result.updated_response.ask)}%`,
    });
  }

  // update response
  const responseIndex = state.player.item.rfq_responses.findIndex(
    response => response.id === result.updated_response.id
  );
  state.player.item.rfq_responses[responseIndex] = result.updated_response;

  // add interbank_deal if any
  if (result.interbank_deal) {
    if (state.player.item.interbank_deals === undefined) {
      state.player.item.interbank_deals = [];
    }
    state.player.item.interbank_deals = [
      ...state.player.item.interbank_deals,
      result.interbank_deal,
    ];
  }

  // update wallet if any
  if (result.updated_wallet) {
    const updatedWallet = result.updated_wallet;
    const walletIndex = state.player.item.wallets.findIndex(
      existing_wallet => existing_wallet.id === updatedWallet.id
    );
    state.player.item.wallets[walletIndex] = updatedWallet;
  }

  // update market_value if any
  if (result.new_mkt_val) {
    state.player.item.market_value = result.new_mkt_val;
  }
});

appManager.createSocketListener<
  {
    updated_rfq: RFQRequest;
    interbank_deal?: InterBankDeal;
    updated_wallet?: Wallet;
    new_mkt_val?: number;
  },
  // unknown,
  // UpdateFins[],
  AppState
>('updated_rfq_after_hit', (state, result) => {
  // update request
  const requestIndex = state.player.item.sent_rfq_requests.findIndex(
    request => request.id === result.updated_rfq.id
  );
  state.player.item.sent_rfq_requests[requestIndex] = result.updated_rfq;

  // add interbank_deal if any
  if (result.interbank_deal) {
    if (state.player.item.interbank_deals === undefined) {
      state.player.item.interbank_deals = [];
    }
    state.player.item.interbank_deals = [
      ...state.player.item.interbank_deals,
      result.interbank_deal,
    ];
  }

  // update wallet if any
  if (result.updated_wallet) {
    const updatedWallet = result.updated_wallet;
    const walletIndex = state.player.item.wallets.findIndex(
      existing_wallet => existing_wallet.id === updatedWallet.id
    );
    state.player.item.wallets[walletIndex] = updatedWallet;
  }

  // update market_value if any
  if (result.new_mkt_val) {
    state.player.item.market_value = result.new_mkt_val;
  }
});

appManager.createSocketListener<
  { updated_rfq: RFQRequest },
  // unknown,
  // UpdateFins[],
  AppState
>('canceled_own_rfq', (state, result) => {
  const requestIndex = state.player.item.sent_rfq_requests.findIndex(
    request => request.id === result.updated_rfq.id
  );
  state.player.item.sent_rfq_requests[requestIndex] = result.updated_rfq;
});

appManager.createSocketListener<
  {
    new_interbank_interest: number;
    updated_deals: InterBankDeal[];
  },
  // unknown,
  // UpdateFins[],
  AppState
>('update_interbank_interest', (state, result) => {
  // console.log('update_interbank_interest', result);
  state.player.item.interbank_interest = result.new_interbank_interest;
  state.player.item.interbank_deals = result.updated_deals;
});

export const sendRFQResponse = appManager.createApi<
  { response_id: number; bid: number; ask: number },
  { locked_in_rfq_response: RFQResponse },
  AppState
>('SEND_RFQ_RESPONSE', {
  path: '/players/send_rfq_response',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
    if (state.player.item.rfq_responses === undefined) {
      state.player.item.rfq_responses = [];
    }
    // state.player.item.rfq_responses.push(result.locked_in_rfq_response);
    const responseIndex = state.player.item.rfq_responses.findIndex(
      response => response.id === result.locked_in_rfq_response.id
    );
    state.player.item.rfq_responses[responseIndex] =
      result.locked_in_rfq_response;
  },
});

appManager.createSocketListener<
  {
    response: RFQResponse;
  },
  // unknown,
  // UpdateFins[],
  AppState
>('new_rfq_request_which_awaits_a_response', (state, result) => {
  // console.log('new request received, response: ', result);
  notification.info({
    message: 'New RFQ received!',
    description: `From ${
      result.response.requesting_player_name
    }; Amount: ${formatters.commasNoDigits(result.response.amount)}`,
  }); // TODO: add a more detailed description
  if (state.player.item.rfq_responses === undefined) {
    state.player.item.rfq_responses = [];
  }
  // state.player.item.rfq_responses.push(result.response);
  state.player.item.rfq_responses = [
    ...state.player.item.rfq_responses,
    result.response,
  ];
});

appManager.createSocketListener<
  {
    updated_response: RFQResponse;
  },
  // unknown,
  // UpdateFins[],
  AppState
>('request_for_response_canceled', (state, result) => {
  // console.log('response canceled: ', result);
  notification.info({
    message: `Bank ${
      result.updated_response.requesting_player_name
    } canceled their ${formatters.commasNoDigits(
      result.updated_response.amount
    )} RFQ!`,
  });
  const responseIndex = state.player.item.rfq_responses.findIndex(
    response => response.id === result.updated_response.id
  );

  state.player.item.rfq_responses[responseIndex] = result.updated_response;
});

appManager.createSocketListener<
  {
    updated_request_with_new_response: RFQRequest;
    responding_player_name: string;
  },
  // unknown,
  // UpdateFins[],
  AppState
>('new_rfq_response_locked_in', (state, result) => {
  // console.log('updated_response: ', result);
  notification.info({
    message: `Bank ${
      result.responding_player_name
    } sent a quote for your ${formatters.commasNoDigits(
      result.updated_request_with_new_response.amount
    )} RFQ!`,
  });
  const requestIndex = state.player.item.sent_rfq_requests.findIndex(
    request => request.id === result.updated_request_with_new_response.id
  );

  state.player.item.sent_rfq_requests[requestIndex] =
    result.updated_request_with_new_response;
});
